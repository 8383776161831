// Ant.d global theme, used in main index.js
export const HyperFlowGlobalTheme = {
    cssVar: true,
    token: {
        colorPrimary: "#1890ff", // Primary color
        fontFamily: "DM Sans, san serif", // Global font family
        fontSize: 13, // Global font size
        colorText: "#1a2c38", // Global text color
        colorBgBase: "#f1f5f9", // '#f0f2f5', // Background color
    },
    components: {
        Menu: {
            inlineIndent: 16,
            itemSelectedColor: "#0084ff",
            itemSelectedBg: "#edf7ff",
        },
    },
};
