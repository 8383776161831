import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useLocation } from "react-router-dom";
import { enableMapSet, setAutoFreeze } from "immer";
import { useUserStore } from "apps/shared/stores/user";
import { httpAPI } from "apps/shared/apis/http";
import { apiPaths } from "@mirinae/defines/paths";
import Gate from "./pages/Gate";

import HyperFlowGlobalStyling from "@hyperflow/modules/defines/HyperFlowGlobalStyling";
import HyperFlowRoutes from "./hyperflowRoutes";
import flowgraphEngine from "@hyperflow/modules/engines/flowgraph";
import TestChatbot from "@hyperflow/pages/TestChatbot";

// immer global settings
enableMapSet();
setAutoFreeze(false);

const HyperFlowApp = () => {
    const { user, methods: userMethods } = useUserStore(state => state);
    const [hasChecked, setHasChecked] = useState(false);
    const location = useLocation();
    const isTestChatbot = location.pathname.match(/\/chatbot(\/)*/); // mild hack to dodge auth for standalone chatbot for now

    useEffect(() => {
        if (!isTestChatbot) {
            httpAPI("", apiPaths.check, { method: "GET" })
                .then(({ success, user }) => {
                    if (success) {
                        userMethods.setUser(user);
                    }
                    setHasChecked(true);
                })
                .catch(err => {
                    console.log({ err });
                    localStorage.clear();
                    setHasChecked(true);
                });
        }
    }, []);

    useEffect(() => {
        if (!isTestChatbot) flowgraphEngine.initialize();
    }, []);

    if (isTestChatbot) {
        return <TestChatbot />;
    } else if (!hasChecked) {
        return <div />;
    } else {
        return user.id != null ? (
            <RouterWrapper>
                <HyperFlowGlobalStyling />
                <HyperFlowRoutes />
            </RouterWrapper>
        ) : (
            <Gate />
        );
    }
};

const RouterWrapper = styled.div`
    height: 100%;
`;

export default HyperFlowApp;
