import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import { localPaths } from "./modules/defines/paths";

const HyperFlowHome = lazy(() => import(/* webpackChunkName: "HyperFlowHome" */ "./pages/HyperFlowHome"));
const OldUI = lazy(() => import(/* webpackChunkName: "OldUI" */ "./pages/OldUI"));
const Catalog = lazy(() => import(/* webpackChunkName: "Catalog" */ "./pages/Catalog"));
const FlowGraphEditor = lazy(() => import(/* webpackChunkName: "FlowGraphEditor" */ "./pages/FlowGraphEditor"));
const FlowGraphRunner = lazy(() => import(/* webpackChunkName: "FlowGraphRunner" */ "./pages/FlowGraphRunner"));
const LibraryBrowser = lazy(() => import(/* webpackChunkName: "LibraryBrowser" */ "./pages/LibraryBrowser"));
const RunHistories = lazy(() => import(/* webpackChunkName: "RunHistories" */ "./pages/RunHistories"));
const FlowGraphSimulator = lazy(() => import(/* webpackChunkName: "FlowGraphSimulator" */ "./pages/FlowGraphSimulator"));
const Admin = lazy(() => import(/* webpackChunkName: "Admin" */ "./pages/Admin"));

const PrompLabRoutes = () => {
    //     home: '',
    //     catalog: 'catalog',
    //     flowGraphEditor: 'flowgraph-editor',
    //     flowGraphRunner: 'flowgraph-runner',
    //     browser: 'browser',
    //     runHistories: 'run-reviewer'

    return (
        <Suspense fallback={<div />}>
            <Routes>
                <Route path={localPaths.home} element={<HyperFlowHome />} />
                <Route path={localPaths.admin} element={<Admin />} />
                <Route path={localPaths.catalog} element={<Catalog />} />
                <Route path={localPaths.flowGraphEditor} element={<FlowGraphEditor />} />
                <Route path={localPaths.flowGraphRunner} element={<FlowGraphRunner />} />
                <Route path={localPaths.simulator} element={<FlowGraphSimulator />} />
                <Route path={localPaths.browser} element={<LibraryBrowser />} />
                <Route path={localPaths.runHistories} element={<RunHistories />} />

                <Route path={localPaths.oldUI} element={<OldUI />} />

                {/*<Route path="*" element={<ExternalRedirector to="/404.html" reload={true}/>} />*/}
                <Route path="*" element={<HyperFlowHome />} />
            </Routes>
        </Suspense>
    );
};

export default PrompLabRoutes;
