import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components/macro";
import MarkdownView from "react-showdown";

import { useFlowGraphStore, useFlowGraphStoreMethods } from "@hyperflow/modules/stores/flowgraph";
import { useChatbotStore, useChatbotStoreMethods } from "@hyperflow/modules/stores/chatbot";
import {
    PromptButton,
    PromptButtonBlock,
    PromptEditor,
    PromptWrapper,
    SendPrompt,
} from "@hyperflow/components/hyperflow/parameters/Prompt";
import { SpinningGear, SpinningSender } from "@hyperflow/components/ui/spinners";
import flowgraphEngine from "@hyperflow/modules/engines/flowgraph";
import { PromptValue } from "@mirinae/classes/DataValue";
import { Busy } from "@hyperflow/components/hyperflow/HyperFlowWorkspace";

const TestBot = ({}) => {
    const flow = useFlowGraphStore(state => state.flow);
    const bot = useChatbotStore(state => state.bot);
    const [busy, setBusy] = useState(false);
    const { addToChat, clearChathistory } = useChatbotStoreMethods();
    const { setParameter, triggerRunButton, addStepDisplay } = useFlowGraphStoreMethods();
    const editorRef = useRef(null);
    const started = useRef(false);
    const historyLength = useRef(0);
    const chatHistoryRef = useRef(null);

    // this test bot does not use the production bot api, piggy-backs on the IDE fow-graph runner to allow full monitoring in the IDE.
    // the IDE flowgraph engine notes if the test bot is running and redirects or duplicates UI requests & displays appropriately
    useEffect(() => {
        if (!started.current && bot.flowGraph) {
            started.current = true;
            clearChathistory();
            addToChat({ type: "message", text: `Greetings from ${bot.flowGraph.displayName}!` });
            flowgraphEngine.startFlowGraph(bot.flowGraph);
        }
    }, [bot.flowGraph]);

    useEffect(() => {
        const keyDown = e => {
            if (e.metaKey && e.key === "Enter") {
                sendPrompt(editorRef.current.value);
                e.preventDefault();
                e.stopPropagation();
            }
        };
        document.addEventListener("keydown", keyDown);
        return () => document.removeEventListener("keydown", keyDown);
    }, [flow, bot]);

    useEffect(() => {
        if (bot.promptParamUI?.promptButtons?.length > 0 && bot.chatHistory[-1]?.type !== "promptButton") {
            bot.promptParamUI.promptButtons.forEach(pb => {
                addToChat({ type: "promptButton", label: pb.label, promptText: pb.promptText });
            });
        }
        if (
            bot.promptParamUI?.parameter?.value &&
            bot.chatHistory.filter(h => h.type === "user").length === 0 &&
            editorRef.current?.value === ""
        ) {
            editorRef.current.value = bot.promptParamUI.parameter?.value;
        }
    }, [bot.promptParamUI]);

    useEffect(() => {
        if (bot.chatHistory.length > historyLength.current) {
            if (bot.chatHistory.length > 1 && bot.chatHistory[bot.chatHistory.length - 1].type !== "user") {
                setBusy(false);
            }
            scrollToBottom();
            historyLength.current = bot.chatHistory.length;
            // check for single line user-prompts
            document.querySelectorAll(".chatbot-user-prompt").forEach(up => {
                const computedStyle = window.getComputedStyle(up);
                let lineHeight = computedStyle.lineHeight;
                if (lineHeight === "normal") {
                    const fontSize = parseInt(computedStyle.fontSize);
                    lineHeight = fontSize * 1.2 * 1.5;
                } else {
                    lineHeight = parseInt(lineHeight) * 1.5;
                }
                const height = up.clientHeight - 10;
                if (height <= lineHeight) {
                    up.classList.add("single-line");
                }
            });
        }
    }, [bot.chatHistory]);

    const scrollToBottom = () => {
        if (chatHistoryRef.current) {
            chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        }
    };

    useLayoutEffect(() => {
        if (busy) {
            scrollToBottom();
        }
    }, [busy]);

    const sendPrompt = (text, buttonLabel) => {
        setParameter(bot.promptParamUI.pathName, new PromptValue(text));
        addStepDisplay({ promptText: text });
        addToChat({ type: "user", text: buttonLabel || text });
        editorRef.current.value = "";
        setBusy(true);
        triggerRunButton();
    };

    const clickedPromptButton = h => {
        sendPrompt(h.promptText, h.label);
    };

    const cleanup = text => {
        return text.replaceAll(/(\n\s*json\s*\n)|(\s*[\[{]segment:*\s*[\d,\s]+[\]})] *)/gi, " "); // remove bogus json/knowledge seg references emitted by the LLM
    };
    return (
        <ChatBox>
            <ChatHistory ref={chatHistoryRef}>
                {bot.chatHistory.map((h, i) => (
                    <React.Fragment key={i}>
                        {h.type === "generator" ? (
                            <MarkdownView className="chatbot-gen-text" markdown={cleanup(h.text)} options={{ tables: true, emoji: true }} />
                        ) : h.type === "user" ? (
                            <UserPrompt className="chatbot-user-prompt">{h.text}</UserPrompt>
                        ) : h.type === "message" ? (
                            <Message>{h.text}</Message>
                        ) : h.type === "image" && h.image?.data ? (
                            <ImageWrapper>
                                <img src={h.image.data} alt="Generated content" className="chatbot-image" onLoad={scrollToBottom} />
                            </ImageWrapper>
                        ) : h.type === "references" ? (
                            <References>
                                References:
                                {h.references.map((doc, k) => (
                                    <RefDoc key={k}>
                                        {doc.document && <div>Document: {doc.document}</div>}
                                        {doc.pages.map((page, m) => (
                                            <RefPage key={m}>
                                                {page.page && <div>Page {page.page}:</div>}
                                                {page.images.map((img, n) => (
                                                    <img key={n} src={img.data} alt="Reference image" />
                                                ))}
                                                {page.links.map((lnk, n) => (
                                                    <Link key={n} href={lnk.url} target="_blank">
                                                        {lnk.title}
                                                    </Link>
                                                ))}
                                            </RefPage>
                                        ))}
                                    </RefDoc>
                                ))}
                            </References>
                        ) : (
                            h.type === "promptButton" && (
                                <ChatPromptButton className="prompt-btn" onClick={() => clickedPromptButton(h)}>
                                    {h.label}
                                </ChatPromptButton>
                            )
                        )}
                    </React.Fragment>
                ))}
                {(busy || flow.busy) &&
                    (bot.busyMessage?.value ? (
                        <BusyMessage>
                            <SpinningGear />
                            <span>{bot.busyMessage.value}</span>
                        </BusyMessage>
                    ) : (
                        busy && (
                            <Busy>
                                <SpinningGear />
                            </Busy>
                        )
                    ))}
            </ChatHistory>
            <PromptWrapper>
                <PromptEditor defaultValue={bot.userPrompt} ref={editorRef} minRows={3} autoFocus />
                <SendPrompt onClick={() => sendPrompt(editorRef.current.value)}>
                    <SpinningSender spin={false} />
                </SendPrompt>
            </PromptWrapper>
        </ChatBox>
    );
};

const ChatBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    height: 100%;
    background-color: #f0f2ef;
`;

const ChatHistory = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: fit-content;
    //min-height: 25em;
    //max-height: 50em;
    background-color: rgb(240, 242, 239);
    padding: 18px;
    overflow-y: auto;
    font-size: 15px;

    div.prompt-btn + div.prompt-btn {
        margin-top: -15px;
    }

    div.ide-gen-text {
        // bloody react-showdown won't specialize with styled-components directly
        width: 80%;
        text-align: left;
        color: #77684e;
        font-size: 14px;
        margin-bottom: 20px;

        &p {
            margin-block-start: 0;
        }
    }
`;

const ChatHistoryEntry = styled.div`
    max-width: 100%;
    text-align: left;
    color: #77684e;
    font-size: 15px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`;

const UserPrompt = styled(ChatHistoryEntry)`
    text-align: left;
    color: rgb(96, 119, 78);
    display: inline-block;
    font-size: 15px;
    background-color: #e7eae6;
    width: 40%;
    min-width: 300px;
    align-self: end;
    padding: 6px 10px;
    border-radius: 10px;

    &.single-line {
        text-align: right;
    }
`;

const Message = styled(ChatHistoryEntry)`
    font-weight: 600;
    color: #484848;
`;

const ChatPromptButton = styled(PromptButton)`
    align-self: end;
    margin-bottom: 20px;
    min-width: 120px;
    max-width: 325px;
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0 10px;

    img {
        max-width: calc(100% - 20px);
        height: auto;
        border-radius: 8px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
`;

const BusyMessage = styled(Busy)``;

const References = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 20px;

    img {
        border-radius: 8px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        max-width: 380px;
    }

    //* {
    //    margin-left: 4px;
    //}
`;

const RefDoc = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-left: 5px;
`;

const RefPage = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-left: 5px;
`;

const Link = styled.a`
    font-weight: 500;
    font-size: 12px;
    color: #437cbe;
    text-decoration-line: underline;
`;

export default TestBot;
