import React, { useMemo } from "react";
import styled from "styled-components/macro";

import { localDate } from "@mirinae/shared/modules/utils/formatters";
import { StepContentBlock } from "@hyperflow/components/ui/widgets";

// mild hack here, formatters driven by distinctive field names, should be polymorphic or use structured values
export const formatters = {
    elapsed: t => `${(t / 1e9).toFixed(2)}s`,
    created: d => localDate(d * 1000), // assumes d is second since the epoch
};

const StepMetadata = ({ step }) => {
    if (step?.metadata?.value) {
        Object.entries(step.metadata.value).forEach(([k, val]) => console.log("   ", k, val));
    }
    const mdDisplay = useMemo(
        () =>
            step.metadata &&
            Object.entries(step.metadata.value).map(([k, val]) => {
                const label = `${k[0].toUpperCase()}${Array.from(k.substring(1))
                    .map(c => (c === c.toUpperCase() ? ` ${c}` : c))
                    .join("")}`;
                const value = (formatters[k] || (i => i))(val);
                return { label, value };
            }),
        [step.metadata]
    );

    return mdDisplay.length > 0 ? (
        <StepContentBlock
            title="Metadata"
            popupReader
            readerChildren={
                <MetadataTable>
                    {mdDisplay.map((md, i) => (
                        <TableStat key={i}>
                            <TableLabel>{md.label}:</TableLabel>
                            <TableValue>{md.value}</TableValue>
                        </TableStat>
                    ))}
                </MetadataTable>
            }
        >
            <MetadataRow>
                {mdDisplay.map((md, i) => (
                    <Stat key={i}>
                        <Label>{md.label}:</Label>
                        <Value>{md.value}</Value>
                    </Stat>
                ))}
            </MetadataRow>
        </StepContentBlock>
    ) : null;
};

export const MetadataRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #748691;
    font-size: 9px;
    width: max-content;
    flex-wrap: nowrap;
`;

const Stat = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0; /* Prevents columns from shrinking */
    min-width: max-content; /* Columns will expand to fit content */
    padding: 0 6px;
    & + & {
        border-left: thin solid #dcdce0;
    }
`;

const Label = styled.span`
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; /* Ensures the div knows its width and can apply ellipses */
`;

const Value = styled.div`
    font-weight: 800;
`;

const MetadataTable = styled(MetadataRow)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
`;

const TableStat = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-size: 14px;
    width: 100%;
`;

const TableLabel = styled.div`
    font-weight: 500;
    width: 33%;
`;

const TableValue = styled.div`
    font-weight: 800;
`;

export default StepMetadata;
