import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import styled from "styled-components/macro";

import { ParamBlock } from "./ParametersBlock";
import { httpAPI } from "@mirinae/apis/http";
import { apiPaths } from "@mirinae/defines/paths";
import { localDate } from "@mirinae/shared/modules/utils/formatters";
import { StepContentBlock } from "@hyperflow/components/ui/widgets";

const SegmentationSet = ({ step, display, currentStep }) => {
    const [segmentationSet, setSegmentationSet] = useState();
    const [segments, setSegments] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    if (!display.preview) return;

    useEffect(() => {
        httpAPI("", apiPaths.listSegmentationSet, { data: { id: display.segmentationSetID } }).then(response => {
            const {
                data: { segmentationSet, segments, totalCount },
            } = response;
            setSegmentationSet(segmentationSet);
            setSegments(segments);
            setTotalCount(totalCount);
        });
    }, [display]);

    const Segments = ({ columns }) => (
        <>
            <SetHeader>
                <h3>Segment set</h3>
                <strong>Created:</strong>
                <span>{localDate(segmentationSet.created)}</span>
                <strong>Type:</strong>
                <span>{segmentationSet.type}</span>
                <strong>{display.preview ? "Preview" : "Segment"} count:</strong>
                <span>{totalCount}</span>
                {segmentationSet.notes && (
                    <>
                        <strong>Notes:</strong>
                        <span>{segmentationSet.notes}</span>
                    </>
                )}
            </SetHeader>
            <SegmentTable>
                {segments.map((segment, i) => (
                    <SegmentBlock columns={columns}>
                        <Segment key={i}>
                            <span>{segment.segment.text}</span>
                            {/*<TextClipper />*/}
                            {segment.metadata?.length > 0 && (
                                <MetadataRow>
                                    {segment.metadata.map(
                                        (md, i) =>
                                            md.value && (
                                                <Metadata key={i}>
                                                    <span>{md.key}</span>: {md.value}
                                                </Metadata>
                                            )
                                    )}
                                </MetadataRow>
                            )}
                        </Segment>
                    </SegmentBlock>
                ))}
            </SegmentTable>
        </>
    );

    return segmentationSet && currentStep.index === step.index ? (
        <StepContentBlock
            title="Segmentation preview"
            popupReader={{ defaultWidth: 700, defaultHeight: 700, valueStyle: { flexDirection: "column", alignItems: "center" } }}
            expander
            bgColor="#faf0fa"
            borderColor="#e564a1"
            readerChildren={<Segments columns={4} />}
        >
            <Segments columns={2} />
        </StepContentBlock>
    ) : null;
};

const SetHeader = styled.div`
    //border: thin solid lightgray;
    //border-radius: 5px;
    padding: 8px;
    width: calc(100% - 34px);
    margin: 0 0px 0 13px;
    font-size: 12px;
    h3 {
        display: inline-block;
        margin: 0 15px 0 0;
    }
    span {
        margin: 0 6px;
    }
`;

const SegmentTable = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
`;

const Segment = styled.div`
    position: relative;
    border: thin solid #ded9d4;
    border-radius: 15px;
    background-color: white;
    padding: 15px;
    font-size: 10px;
    overflow: auto;
    height: 160px;

    span {
        z-index: 1;
    }
`;

const SegmentBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(${({ columns }) => 100 / columns}% - 40px);
    min-width: 200px;
    max-width: 400px;
`;

const MetadataRow = styled.div`
    border-top: thin solid lightgrey;
    margin-top: 6px;
    padding-top: 5px;
    width: 100%;
    white-space: wrap;
    color: #979797;
`;

const Metadata = styled.div`
    overflow-wrap: break-word; /* Allows breaking of long words */
    word-break: break-all; /* Breaks words at any character */
    white-space: normal; /* Normal text wrapping */

    span {
        font-weight: 600;
        color: #6d6d6d;
    }
`;

const TextClipper = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;
    /* padding-right: 20px; */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 91%);
    height: 3em;
    z-index: 2;
`;

export default SegmentationSet;
