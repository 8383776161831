import React, { useMemo, useEffect } from "react";
import styled from "styled-components/macro";
import { useImmer } from "use-immer";

import flowgraphEngine from "@mirinae/hyperflow/modules/engines/flowgraph";
import { useFlowGraphStoreMethods } from "@mirinae/hyperflow/modules/stores/flowgraph";
import { ParameterUIComponent } from "@mirinae/hyperflow/components/hyperflow/parameters";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { httpAPI } from "@mirinae/apis/http";
import { apiPaths } from "@mirinae/defines/paths";
import { useViewerStore } from "@hyperflow/modules/stores/viewer";
import { useProjectStore } from "@hyperflow/modules/stores/project";
import { StepContentBlock } from "@hyperflow/components/ui/widgets";
import { notification } from "antd";

// various fallback no-display formatting definitions for old flow-graphs lacking following
const nodeFormats = {
    getPrompt: { box: "none", bgColor: "#f0f9fa", borderColor: "#80c3e0", parameters: "expand" },
    getInstructions: { box: "none", bgColor: "#fafaf0", borderColor: "#9c5bc8", parameters: "expand" },
    contextAndGenerate: { parameters: "collapse" },
};

const ParametersStack = ({ flow, step, mode }) => {
    const [collapsingGroupExpanded, setCollapsingGroupExpanded] = useImmer([]);
    const { setParamLock, copyStepParamsToNode, isParameterDefined, setParamUILoading, setStepException } = useFlowGraphStoreMethods();
    const editingEnabled = useViewerStore(state => state.viewer.editingEnabled);
    const project = useProjectStore(state => state.project);
    const [api, contextHolder] = notification.useNotification();

    const paramUIList = useMemo(() => {
        const params = [];
        let runButton = null;
        let collapsingGroup = null;
        let needsRunButton = mode !== "configuring";
        step.parameterUI
            .filter(p => !p.dead)
            .forEach((pui, i) => {
                pui.index = i;
                if (pui.type === "runButton") {
                    if (mode === "configuring") return;
                    runButton = pui;
                    needsRunButton = false;
                    return;
                } else if (pui.type === "branchChoice") {
                    needsRunButton = false;
                }
                if (["startColumn", "endColumn", "newColumn"].includes(pui.type)) return;
                else if (pui.type === "collapsingGroup") {
                    // mild hack to support collapsing paramblocks
                    collapsingGroup = [];
                    if (pui.expand)
                        setCollapsingGroupExpanded(state => {
                            state[pui.index] = true;
                        });
                    params.push({ ...pui, collapsingGroup });
                } else if (pui.type === "endCollapsingGroup") {
                    params[params.length - 1].allLocked = collapsingGroup.every(pui => pui.locked);
                    params[params.length - 1].required = collapsingGroup.some(pui => pui.awaiting && !pui.parameter?.value);
                    collapsingGroup = null;
                } else if (collapsingGroup) {
                    collapsingGroup.push(pui);
                } else {
                    params.push(pui);
                }
            });
        setParamUILoading(false);
        if (collapsingGroup) {
            params[params.length - 1].allLocked = collapsingGroup.every(pui => pui.locked);
            params[params.length - 1].required = collapsingGroup.some(pui => pui.awaiting && !pui.parameter?.value);
            collapsingGroup = null;
        }
        if (mode !== "historical" && needsRunButton && params.length > 0) {
            runButton = { type: "runButton", label: "OK", disabled: true, pathName: "__runButton" };
        }
        return { params, runButton };
    }, [step.parameterUI, step.parameters]);

    const nodeFirstRun = useMemo(() => !flow.steps.find(s => s.nodeID === step.nodeID && step.index !== s.index), [step]);

    useEffect(() => {
        if (step.exception && mode !== "historical") {
            api["error"]({
                message: "Exception during step configuration",
                onClose: () => setStepException(null),
                duration: 0,
                description: (
                    <div>
                        <p>
                            In <strong>{step.exception.method}</strong>
                        </p>
                        <p>{step.exception.exception}</p>
                    </div>
                ),
            });
        }
    }, [step.exception]);

    const setLocked = async (paramUI, lockState) => {
        const resetting = setParamLock(paramUI.index, lockState);
        if (!lockState || isParameterDefined(paramUI.pathName)) {
            if (resetting) {
                flowgraphEngine.resetParameter(paramUI.pathName);
            }
            if (editingEnabled && (lockState || resetting) /*  why only on locking?? */) {
                const updatedFlowGraph = copyStepParamsToNode(step.nodeID);
                /* await */
                httpAPI("", apiPaths.saveFlowGraph, {
                    data: {
                        flowGraph: updatedFlowGraph,
                        projectID: project._id,
                    },
                });
            }
        }
    };

    const Parameter = (paramUI, i = 0) => {
        const ParameterComponent = ParameterUIComponent[paramUI.type];
        return ParameterComponent ? (
            <ParameterComponent
                key={i}
                flow={flow}
                mode={mode}
                step={step}
                paramUI={paramUI}
                setLocked={mode === "historical" ? null : lockState => setLocked(paramUI, lockState)}
                nodeFirstRun={nodeFirstRun}
            />
        ) : null;
    };

    return flow.flowGraph && step.node && paramUIList.params.length > 0 ? (
        <ParamStackWrapper className={`${mode === "historical" ? mode : "active"}-paramblock`}>
            <ParamStack
                title={mode === "historical" && "Parameters"}
                expander={mode === "historical"}
                expanded={(step.node?.logFormat?.parameters || nodeFormats[step.node?.name]?.parameters || "collapse") === "expand"} // default is now collapse
                mode={mode}
                borderColor={step.node.logFormat?.borderColor || nodeFormats[step.node.name]?.borderColor || "#c4c4c4"}
                bgColor={step.node.logFormat?.bgColor || nodeFormats[step.node.name]?.bgColor || "#f5f5f5"}
            >
                {paramUIList.params.map((paramUI, i) =>
                    paramUI.type === "collapsingGroup" ? (
                        <UI.CollapsingFieldBox
                            key={i}
                            title={paramUI.label}
                            expanded={collapsingGroupExpanded[paramUI.index]}
                            setExpanded={() =>
                                setCollapsingGroupExpanded(state => {
                                    state[paramUI.index] = !state[paramUI.index];
                                })
                            }
                            required={paramUI.required}
                            locked={paramUI.allLocked || mode === "historical"}
                        >
                            {paramUI.collapsingGroup.map((subParamUI, j) => Parameter(subParamUI, j))}
                        </UI.CollapsingFieldBox>
                    ) : (
                        Parameter(paramUI, i)
                    )
                )}
                {paramUIList.runButton && <RunButton>{Parameter(paramUIList.runButton)}</RunButton>}
            </ParamStack>
            {contextHolder}
        </ParamStackWrapper>
    ) : null;
};

const ParamStackWrapper = styled.div`
    position: relative;
`;

const ParamStack = styled(StepContentBlock)`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    background-color: #ffffff;
    gap: 8px;
    overflow-y: auto;

    #editor & {
        // padding: 8px 16px;
        background-color: #ffffff; // #f8fbff; // white;
        border: none;
        width: inherit;

        ::before {
            top: 21px;
        }

        .locked-param {
            background-color: #f4f4f4;
            color: grey;
        }

        .active-paramblock .unlocked-param {
            background-color: white;
        }

        & select {
            border-radius: 6px;
            border: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border);

            :focus {
                outline: var(--ant-line-width) solid var(--ant-color-primary);
            }

            :hover {
                outline: var(--ant-line-width) solid var(--ant-color-primary);
            }
        }

        & option {
            background-color: white;
        }
    }
`;

const RunButton = styled.div`
    //position: absolute;
    //bottom: -50px;
    //left: 50%;
    //transform: translateX(-50%);
`;

export default ParametersStack;
