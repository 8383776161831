import React from "react";
import styled from "styled-components/macro";

export const SpinningSender = ({ width = 34, height = 34, spin }) => {
    return (
        <SenderSVG width={width} height={height} spin={spin} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.75 51.6667V10.3333L56.8333 31L7.75 51.6667ZM12.9167 43.9167L43.5292 31L12.9167 18.0833V27.125L28.4167 31L12.9167 34.875V43.9167Z"
                fill="#30a0a0"
            />
        </SenderSVG>
    );
};

const SenderSVG = styled.svg`
    perspective: 200px;
    ${props => props.spin && `animation: rotateX 0.55s infinite linear`};
    @keyframes rotateX {
        from {
            transform: rotateX(0deg);
        }
        to {
            transform: rotateX(360deg);
        }
    }
`;

export const SpinningGear = ({ width = 20, height = 20 }) => {
    return (
        <SpinningGearSVG width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
            <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z" />
        </SpinningGearSVG>
    );
};

const SpinningGearSVG = styled.svg`
    fill: #a48787;
    animation: rotate 1.55s infinite linear;
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

export const Orbits = ({ width = 18, height = 18, style }) => {
    return <OrbitDiv style={style} width={width} height={height} />;
};

const OrbitDiv = styled.div`
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    color: #3a8133;

    :before,
    :after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        border-radius: 50%;
        transform: rotateX(70deg);
        animation: 0.5s spin linear infinite;
    }

    :after {
        color: #d79c43;
        transform: rotateY(70deg);
        animation-delay: 0.2s;
    }

    //:before {
    //    content: '•';
    //    font-size: 20px;
    //    color: red;
    //    position: absolute;
    //    top: 10px;
    //    left: 10px;
    //}

    @keyframes rotate {
        0% {
            transform: translate(-50%, -50%) rotateZ(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotateZ(360deg);
        }
    }

    @keyframes rotateccw {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(-360deg);
        }
    }

    @keyframes spin {
        0%,
        100% {
            box-shadow: 0.5em 0px 0 0px currentcolor;
        }
        12% {
            box-shadow: 0.5em 0.5em 0 0 currentcolor;
        }
        25% {
            box-shadow: 0 0.5em 0 0px currentcolor;
        }
        37% {
            box-shadow: -0.5em 0.5em 0 0 currentcolor;
        }
        50% {
            box-shadow: -0.5em 0 0 0 currentcolor;
        }
        62% {
            box-shadow: -0.5em -0.5em 0 0 currentcolor;
        }
        75% {
            box-shadow: 0px -0.5em 0 0 currentcolor;
        }
        87% {
            box-shadow: 0.5em -0.5em 0 0 currentcolor;
        }
    }
`;
