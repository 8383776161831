import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConfigProvider } from "antd";

// import GTM from '@mirinae/apis/GoogleAnalytics';
import { os, browser } from "@mirinae/libs/userAgent";

import AppBase from "@mirinae/apps/shared/components/appBase";
import HyperFlowApp from "./HyperFlowApp";
import { HyperFlowGlobalTheme } from "@hyperflow/modules/defines/styles";

const root = ReactDOM.createRoot(document.getElementById("root"));

// nuke useless 50 line deprecation warnings!
const originalConsoleError = console.error;
console.error = (...args) => {
    if (typeof args[0] === "string" && args[0].includes("is deprecated")) {
        originalConsoleError(args[0]); // Log only the warning message
        return;
    }
    originalConsoleError(...args); // Log other errors/warnings normally
};

if (os.isAndroid && browser.isInApp) {
    window.location.href = `intent://${window.location.href.replace(window.location.protocol, "").replace("//", "")}#Intent;scheme=https;package=com.android.chrome;end`;
} else if (os.isIos && browser.isInApp) {
    window.location.href = `googlechrome://${window.location.href.replace(window.location.protocol, "").replace("//", "")}`;
} else {
    // GTM.initialize();
    root.render(
        <ConfigProvider theme={HyperFlowGlobalTheme}>
            {/*<React.StrictMode>*/}
            <CookiesProvider>
                <GoogleOAuthProvider clientId="88024450945-ivs1idd5sd8p1mv4501h7jtpvrr2aga9.apps.googleusercontent.com">
                    <BrowserRouter>
                        <AppBase>
                            <Routes>
                                {/* need this to properly resolve paths when mounting app subpaths */}
                                <Route path="hyperflow/*" element={<HyperFlowApp />} />
                                <Route path="*" element={<HyperFlowApp />} />
                            </Routes>
                        </AppBase>
                    </BrowserRouter>
                </GoogleOAuthProvider>
            </CookiesProvider>
            {/*</React.StrictMode>*/}
        </ConfigProvider>
    );
}
