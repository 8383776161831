import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { useImmer } from "use-immer";
import Draggable from "react-draggable";
import TextareaAutosize from "react-textarea-autosize";
import { createNoise3D } from "simplex-noise";
const noise3D = createNoise3D();

import {
    Engine,
    Scene,
    ArcRotateCamera,
    Vector3,
    HemisphericLight,
    MeshBuilder,
    StandardMaterial,
    Color3,
    Color4,
    PointerEventTypes,
    KeyboardEventTypes,
} from "@babylonjs/core";

import { httpAPI } from "@mirinae/apis/http";
import { apiPaths } from "@mirinae/defines/paths";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { SimpleButton } from "@mirinae/hyperflow/components/ui/widgets";
import { Orbits, SpinningSender } from "@hyperflow/components/ui/spinners";
import { useFlowGraphStore } from "@hyperflow/modules/stores/flowgraph";

let light1;

const rangeOptions = [
    { label: "Number of neighbors", value: "count" },
    { label: "Distance from target", value: "distance" },
    { label: "Cluster max separation", value: "cluster" },
];

const modeOptions = [
    { label: "Local structure", value: "PCA+TSNE" },
    { label: "Large-scale structure", value: "PCA" },
    { label: "Clusters", value: "UMAP" },
];

const metricOptions = [
    { label: "Euclidean distance", value: "euclidean" },
    { label: "Cosine", value: "cosine" },
    { label: "Dot-product", value: "dot-prod" },
];

const VectorDBVisualize = ({ step, display, currentStep }) => {
    const stepContext = useFlowGraphStore(state => state.flow.steps[state.flow.currentStepIndex].stepContext);
    const [mode, setMode] = useState("PCA");
    const [vectors, setVectors] = useState([]);
    const [originalVectors, setOriginalVectors] = useState([]);
    const [segmentIDs, setSegmentIDs] = useState([]);
    const [selectedVectorIndex, setSelectedVectorIndex] = useState(null);
    const [bounds, setBounds] = useState({});
    const [selectedSegmentIndexes, setSelectedSegmentIndexes] = useState([]);
    const [selectedSegments, setSelectedSegments] = useState([]);
    const [queryVectors, setQueryVectors] = useState(null);
    const [metric, setMetric] = useState("euclidean");
    const [range, setRange] = useState(0);
    const [minMaxDistance, setMinMaxDistance] = useState({ min: 0, max: 100 });
    const [sliderValue, setSliderValue] = useState(0);
    const [rangeType, setRangeType] = useState("count");
    const [expanded, setExpanded] = useImmer([]);
    const [open, setOpen] = useState(true);

    const clickExpander = (e, i) => {
        setExpanded(state => {
            state[i] = !state[i];
        });
    };

    const canvasRef = useRef(null);
    const engineRef = useRef(null);
    const sceneRef = useRef(null);
    const cameraRef = useRef(null);
    const vectorRef = useRef(null);
    const queryVectorRef = useRef(null);
    const spheresRef = useRef([]);
    const queryInputRef = useRef(null);

    const segmentCache = useRef({});
    const distanceList = useRef([]);
    const distanceSortedList = useRef([]);
    const segIDToDistanceMap = useRef({});
    const fullSpaceSortedDistanceList = useRef([]);
    const fullSpaceDistanceList = useRef([]);

    useEffect(() => {
        httpAPI("", apiPaths.getVectorDBVisualization, {
            data: { id: display.previewVectorDBID, parameters: { algorithm: mode }, stepContext },
        }).then(response => {
            const {
                data: { vectors, originalVectors, segmentIDs },
            } = response;
            setVectors(vectors || []);
            setOriginalVectors(originalVectors || []);
            setSegmentIDs(segmentIDs || []);
        });
    }, [display]);

    const getBounds = (vectors, originalVectors) => {
        setBounds({
            reduced: {
                max: vectors.reduce((m, [x, y, z]) => ({ x: Math.max(x, m.x), y: Math.max(y, m.y), x: Math.max(x, m.x) }), {
                    x: -1,
                    y: -1,
                    z: -1,
                }),
            },
        });
    };

    const buildDistanceList = (targetVector, originalTargetVector) => {
        console.log("buildDistanceList", targetVector);
        const targetVec3 = Vector3.FromArray(targetVector);
        distanceList.current = vectors.map((v, i) => {
            const distance = Vector3.Distance(Vector3.FromArray(v), targetVec3);
            segIDToDistanceMap.current[segmentIDs[i]] = { distance };
            return [distance, i];
        });
        distanceSortedList.current = [...distanceList.current].sort((a, b) => a[0] - b[0]);
        const minMaxDist = { min: 1e6, max: 0 };
        fullSpaceDistanceList.current = originalVectors.map((v, i) => {
            let sum = 0;
            for (let i = 0; i < v.length; i++) {
                sum += (originalTargetVector[i] - v[i]) ** 2;
            }
            const fullSpaceDistance = Math.sqrt(sum);
            if (segmentIDs[i]) {
                segIDToDistanceMap.current[segmentIDs[i]].fullSpaceDistance = fullSpaceDistance;
            }
            if (fullSpaceDistance > 0) {
                minMaxDist.min = Math.min(minMaxDist.min, fullSpaceDistance);
                minMaxDist.max = Math.max(minMaxDist.max, fullSpaceDistance);
            }
            return [fullSpaceDistance, i];
        });
        fullSpaceSortedDistanceList.current = [...fullSpaceDistanceList.current].sort((a, b) => a[0] - b[0]);
        setMinMaxDistance(minMaxDist);
    };

    useEffect(() => {
        if (selectedVectorIndex !== null) {
            buildDistanceList(vectors[selectedVectorIndex], originalVectors[selectedVectorIndex]);
            setSelectedSegmentIndexes([selectedVectorIndex]);
        } else {
            setSelectedSegmentIndexes([]);
        }
    }, [selectedVectorIndex]);

    useEffect(() => {
        // ensure segments for latest selection are in the cache
        const targetVector = queryVectors ? queryVectors.queryVector : selectedVectorIndex ? vectors[selectedVectorIndex] : null;
        if (targetVector !== null) {
            const selectedSegIDs = selectedSegmentIndexes.map(i => segmentIDs[i]);
            const segIDsToLoad = selectedSegIDs.filter(segID => !(segID in segmentCache.current));
            httpAPI("", apiPaths.getSegments, { data: { segmentIDs: segIDsToLoad } }).then(response => {
                const {
                    data: { segments },
                } = response;
                segments.forEach(seg => {
                    segmentCache.current[seg._id.toString()] = seg;
                });
                setSelectedSegments(selectedSegIDs.map(i => segmentCache.current[i]));
            });
        } else {
            setSelectedSegments([]);
        }
    }, [selectedSegmentIndexes]);

    const resetSelection = () => {
        if (vectorRef.current) {
            // clear any current selections
            vectorRef.current.dispose();
            vectorRef.current = null;
        }
        if (spheresRef.current) {
            spheresRef.current.forEach(s => {
                // s.material.diffuseColor = Color3.Blue();
                s.diffuseColor = getColorFromPosition(s.position.x, s.position.y, s.position.z);
                s.material.alpha = 1;
                if (s.vector) {
                    s.vector.dispose();
                }
            });
        }
        setSelectedSegmentIndexes([]);
        setSelectedVectorIndex(null);
        setSelectedSegments([]);
        setQueryVectors(null);
        setRange(0);
        setSliderValue(0);
        setExpanded([]);
    };

    // 3D pos-to-color option 1: map position to Color3 using trilinear interpolation of corner hues
    function getColorFromPosition(_x, _y, _z) {
        let [x, y, z] = [_x + 0.5, _y + 0.5, _z + 0.5];
        // Define the hues at each corner of the cube
        const h000 = 0 / 8; // Corner at (0, 0, 0)
        const h100 = 1 / 8; // Corner at (1, 0, 0)
        const h110 = 2 / 8; // Corner at (1, 1, 0)
        const h010 = 3 / 8; // Corner at (0, 1, 0)
        const h001 = 4 / 8; // Corner at (0, 0, 1)
        const h101 = 5 / 8; // Corner at (1, 0, 1)
        const h111 = 6 / 8; // Corner at (1, 1, 1)
        const h011 = 7 / 8; // Corner at (0, 1, 1)

        // Trilinear interpolation weights
        x *= 2;
        y *= 2;
        z *= 2;
        const w000 = (1 - x) * (1 - y) * (1 - z);
        const w100 = x * (1 - y) * (1 - z);
        const w110 = x * y * (1 - z);
        const w010 = (1 - x) * y * (1 - z);
        const w001 = (1 - x) * (1 - y) * z;
        const w101 = x * (1 - y) * z;
        const w111 = x * y * z;
        const w011 = (1 - x) * y * z;

        // Interpolate the hue based on the weights
        let h = (w000 * h000 + w100 * h100 + w110 * h110 + w010 * h010 + w001 * h001 + w101 * h101 + w111 * h111 + w011 * h011) % 1;

        // Saturation and Value are set to 1.0
        let s = 1.0;
        let v = 1.0;

        // Use Babylon.js's built-in function to get the Color3
        return Color3.FromHSV(h * 360, s, v);
    }

    // 3D pos-to-color option 2: use perlin's space-filling noise function at low frequency
    const coordinateToColor = (_x, _y, _z) => {
        const [x, y, z] = [_x + 0.5, _y + 0.5, _z + 0.5];
        // Normalize coordinates to -1 to 1 range if not already
        const nx = x * 2 - 1;
        const ny = y * 2 - 1;
        const nz = z * 2 - 1;

        // Scale factor for noise (lower = smoother transitions)
        // Adjust these values to change the "frequency" of color changes
        const scale = 0.5;

        // Get three different noise values for hue, saturation, and value
        // Using slightly offset coordinates for each to get independent patterns
        const noiseHue = noise3D(nx * scale, ny * scale, nz * scale);

        const noiseSat = noise3D(
            nx * scale + 100, // Offset to get different pattern
            ny * scale + 100,
            nz * scale + 100
        );

        const noiseVal = noise3D(
            nx * scale + 200, // Different offset for value
            ny * scale + 200,
            nz * scale + 200
        );

        // Map noise values (-1 to 1) to appropriate ranges
        const hue = (noiseHue + 1) * 0.5; // Map to 0-1
        // const hue = (Math.abs(noiseHue + 1) * 0.5) % 1; // Map to 0-1
        const saturation = 0.5 + noiseSat * 0.25; // Map to 0.25-0.75
        const value = 0.7 + noiseVal * 0.3; // Map to 0.4-1.0

        return Color3.FromHSV(
            hue * 360, // Hue in degrees (0-360)
            1, // saturation, // Saturation (0-1)
            1 // value // Value/Brightness (0-1)
        );
    };

    useEffect(() => {
        // Babylon JS 3D vector plot
        if (canvasRef.current) {
            console.log("creating canvas");
            const engine = new Engine(canvasRef.current, true);
            engineRef.current = engine;
            const scene = new Scene(engine);
            sceneRef.current = scene;
            scene.clearColor = new Color4(1, 1, 1, 1);

            // Camera
            const camera = new ArcRotateCamera("camera1", Math.PI / 4, 1.316, 1.5, Vector3.Zero(), scene);

            camera.inertia = 0.75;
            camera.angularSensibilityX = 400;
            camera.angularSensibilityY = 400;
            camera.panningInertia = 0.75;
            camera.panningSensibility = 400;
            camera.lowerAlphaLimit = null;
            camera.upperAlphaLimit = null;
            camera.lowerBetaLimit = null;
            camera.upperBetaLimit = null;
            camera.lowerRadiusLimit = 0.051;
            camera.minZ = 0.01; // for debugging!!
            cameraRef.current = camera;

            camera.attachControl(canvasRef.current, true);

            // Lights
            light1 = new HemisphericLight("light1", new Vector3(0, 1, 0), scene);
            light1.diffuse = new Color3(0.6, 0.6, 0.6);
            const light2 = new HemisphericLight("light2", new Vector3(0, -1, 0), scene);
            light2.diffuse = new Color3(0.6, 0.6, 0.6);
            light2.specular = new Color3(0.25, 0.25, 0.25);

            // Create spheresRef.current for each point
            spheresRef.current.splice(0);
            vectors.forEach((point, idx) => {
                const sphere = MeshBuilder.CreateSphere(`sphere${idx}`, { diameter: 0.01 }, scene);
                sphere.position = new Vector3(...point);
                const sphereMaterial = new StandardMaterial(`material${idx}`, scene);
                //sphereMaterial.diffuseColor = coordinateToColor(sphere.position.x, sphere.position.y, sphere.position.z);
                sphereMaterial.diffuseColor = getColorFromPosition(sphere.position.x, sphere.position.y, sphere.position.z);

                sphere.material = sphereMaterial;
                sphere.metadata = { vectorIndex: idx };
                sphere.isPickable = true;
                spheresRef.current.push(sphere);
                if (queryVectors) sphere.material.alpha = 0.2;
            });

            // Create axes
            const axisLength = 1;
            const makeAxis = (v, color) => {
                const axis = MeshBuilder.CreateLines(
                    `axis${color}`,
                    { points: [v.scale(-axisLength), v.scale(axisLength)], updatable: false },
                    scene
                );
                axis.color = color;
                axis.isPickable = false;

                // Adding tick marks on the axis
                const tickLength = 0.01;
                for (let i = -0.9; i <= axisLength; i += 0.1) {
                    const tickCenter = v.scale(i);
                    const tick1Direction = new Vector3(v.y, v.z, v.x); // Perpendicular direction 1
                    const tick2Direction = new Vector3(v.z, v.x, v.y); // Perpendicular direction 2
                    const tick1 = MeshBuilder.CreateLines(
                        `tick${i}`,
                        {
                            points: [tickCenter, tickCenter.add(tick1Direction.scale(tickLength))],
                            updatable: false,
                        },
                        scene
                    );
                    tick1.color = color;
                    tick1.isPickable = false;
                    const tick2 = MeshBuilder.CreateLines(
                        `tick${i}${color}b`,
                        {
                            points: [tickCenter, tickCenter.add(tick2Direction.scale(tickLength))],
                            updatable: false,
                        },
                        scene
                    );
                    tick2.color = color;
                    tick2.isPickable = false;
                }
            };
            const axisColor = Color3.FromHexString("#8e8e8e");
            makeAxis(new Vector3(1, 0, 0), axisColor);
            makeAxis(new Vector3(0, 1, 0), axisColor);
            makeAxis(new Vector3(0, 0, 1), axisColor);

            // interactivity
            let shiftPressed = false;

            // Disable camera rotation when shift is held down
            scene.onKeyboardObservable.add(kbInfo => {
                if (kbInfo.type === KeyboardEventTypes.KEYDOWN && kbInfo.event.key === "Shift") {
                    camera.detachControl(canvasRef.current);
                    shiftPressed = true;
                } else if (kbInfo.type === KeyboardEventTypes.KEYUP && kbInfo.event.key === "Shift") {
                    camera.attachControl(canvasRef.current, true);
                    shiftPressed = false;
                }
            });

            // Handle sphere selection
            scene.onPointerObservable.add(pointerInfo => {
                switch (pointerInfo.type) {
                    case PointerEventTypes.POINTERDOWN:
                        console.log("pointer down", pointerInfo.pickInfo.hit, pointerInfo.pickInfo.pickedMesh);
                        if (/*shiftPressed && */ pointerInfo.pickInfo.hit && pointerInfo.pickInfo.pickedMesh) {
                            let pickedMesh = pointerInfo.pickInfo.pickedMesh;
                            console.log(`You clicked on sphere: ${pickedMesh.name}`);
                            if (pickedMesh.metadata?.vectorIndex !== undefined) {
                                if (vectorRef.current) {
                                    // clear any current selections
                                    resetSelection();
                                }

                                pickedMesh.material.diffuseColor = Color3.Red();
                                setSelectedVectorIndex(pickedMesh.metadata.vectorIndex);
                                spheresRef.current.forEach((s, i) => {
                                    if (i !== pickedMesh.metadata.vectorIndex) s.material.alpha = 0.2;
                                });
                                const vector = MeshBuilder.CreateLines(
                                    `vector${pickedMesh.metadata.vectorIndex}`,
                                    {
                                        points: [Vector3.Zero(), Vector3.FromArray(vectors[pickedMesh.metadata.vectorIndex])],
                                        updatable: false,
                                    },
                                    scene
                                );
                                vector.color = Color3.Red();
                                vector.isPickable = false;
                                vectorRef.current = vector;
                                break;
                            } else {
                                setSelectedVectorIndex(null);
                            }
                        }
                        break;
                }
            });

            // Render loop
            engine.runRenderLoop(() => {
                scene.render();
            });

            return () => {
                engine.dispose();
            };
        }
    }, [vectors]);

    useEffect(() => {
        if (queryVectorRef.current) {
            queryVectorRef.current.forEach(o => o.dispose());
            queryVectorRef.urrent = [];
        }

        if (queryVectors && sceneRef.current) {
            // draw test-query vector
            const qvec3 = Vector3.FromArray(queryVectors.queryVector);
            const sphere = MeshBuilder.CreateSphere(`sphere-query`, { diameter: 0.01 }, sceneRef.current);
            sphere.position = qvec3;
            const sphereMaterial = new StandardMaterial(`material-sphere-query`, sceneRef.current);
            sphereMaterial.diffuseColor = Color3.FromHexString("#ff9900");
            sphereMaterial.ambientColor = new Color3(0.2, 0.2, 0.2);
            sphere.material = sphereMaterial;
            sphere.isPickable = false;
            const vector = MeshBuilder.CreateLines(`vector-query`, { points: [Vector3.Zero(), qvec3], updatable: false }, sceneRef.current);
            vector.color = sphereMaterial.diffuseColor;
            vector.isPickable = false;

            queryVectorRef.current = [sphere, vector];
        }
    }, [queryVectors, vectors, sceneRef.current]);

    const rangleSliderChange = e => {
        setRange(e.target.value);
        let selectedIndexes;
        const distance = minMaxDistance.min + (Number(e.target.value) / 200) * (minMaxDistance.max - minMaxDistance.min);
        const count = Math.floor(Number(e.target.value) / 5);
        const sortedList = fullSpaceSortedDistanceList; // distanceSortedList;
        switch (rangeType) {
            case "count":
                selectedIndexes = sortedList.current.slice(0, count).map(dc => dc[1]);
                setSliderValue(count);
                break;
            case "distance":
                selectedIndexes = sortedList.current.filter(dc => dc[0] < distance).map(dc => dc[1]);
                setSliderValue(distance.toString().substring(0, 4));
                break;
            case "cluster": {
                const cluster = new Set([selectedVectorIndex]);
                const indexesToCheck = [selectedVectorIndex];
                while (indexesToCheck.length > 0) {
                    const checker = indexesToCheck.pop();
                    const checkerVec = Vector3.FromArray(vectors[checker]);
                    vectors.forEach((v, i) => {
                        // yikes !! O(n-squared)!!  needs bsp or some such
                        const dv = Vector3.Distance(Vector3.FromArray(v), checkerVec);
                        if (dv < distance && !cluster.has(i)) {
                            cluster.add(i);
                            indexesToCheck.push(i);
                        }
                    });
                }
                selectedIndexes = Array.from(cluster);
                setSliderValue(distance.toString().substring(0, 4));
                break;
            }
        }
        //
        setSelectedSegmentIndexes(selectedIndexes);
        spheresRef.current.forEach((s, i) => {
            if (i !== selectedVectorIndex) s.material.alpha = 0.2;
            if (s.vector) {
                s.vector.dispose();
            }
        });
        selectedIndexes.forEach(i => {
            if (i !== selectedVectorIndex && spheresRef.current[i]) {
                const s = spheresRef.current[i];
                s.material.diffuseColor = Color3.FromHexString("#be78ff");
                s.material.alpha = 1;
                const selectedVec = MeshBuilder.CreateLines(
                    `vector-sel${i}`,
                    { points: [Vector3.Zero(), s.position], updatable: false },
                    sceneRef.current
                );
                selectedVec.color = Color3.FromHexString("#be88d0");
                selectedVec.isPickable = false;
                s.vector = selectedVec;
            }
        });
    };

    const metricTypeChange = e => {
        setMetric(e.target.value);

        switch (e.target.value) {
            case "euclidean": {
                break;
            }

            case "cosine": {
                const tmp = vectors.map(vector => {
                    const vec3 = new Vector3(vector[0], vector[1], vector[2]);
                    vec3.normalize();
                    return [vec3.x, vec3.y, vec3.z];
                });
                setVectors(tmp);

                // function normalizeNDimensionalVectors(vectors) {
                //     return vectors.map(vector => {
                //         // Calculate the norm (magnitude) of the vector
                //         const norm = Math.sqrt(vector.reduce((acc, val) => acc + val * val, 0));
                //
                //         // Check for zero length vector to avoid division by zero
                //         if (norm === 0) return vector.map(() => 0);
                //
                //         // Normalize each component of the vector
                //         return vector.map(component => component / norm);
                //     });
                // }
                //
                // // Example usage:
                // let vectors = [
                //     [1, 2, 3, 4],
                //     [5, 6, 7, 8],
                //     [9, 10, 11, 12]
                // ];
                //
                // let normalizedVectors = normalizeNDimensionalVectors(vectors);
                // console.log(normalizedVectors);

                break;
            }
        }
    };

    const closeVisualizer = e => {
        setOpen(false);
    };

    const rangeTypeChange = e => {
        setRangeType(e.currentTarget.value);
        setRange(0);
        setSliderValue(0);
    };

    const resetView = () => {
        if (cameraRef.current) {
            cameraRef.current.target = Vector3.Zero();
            cameraRef.current.radius = 1.5;
            cameraRef.current.alpha = Math.PI / 4;
            cameraRef.current.beta = 1.316;
            resetSelection();
            setQueryVectors(null);
        }
    };

    const centerOnSelection = () => {
        let x = 0,
            y = 0,
            z = 0;
        let selCount = selectedSegmentIndexes.length;
        if (queryVectors) {
            [x, y, z] = queryVectors.queryVector;
            selCount += 1;
        }
        selectedSegmentIndexes.forEach(i => {
            x += vectors[i][0];
            y += vectors[i][1];
            z += vectors[i][2];
        });
        cameraRef.current.target = new Vector3(x / selCount, y / selCount, z / selCount);
    };

    const visualizeSelection = () => {
        setVectors([]);
        setSelectedSegments([]);
        const selection = selectedSegmentIndexes.map(i => segmentIDs[i]);
        httpAPI("", apiPaths.getVectorDBVisualization, {
            data: { id: display.previewVectorDBID, selection, parameters: { algorithm: mode }, stepContext },
        }).then(response => {
            const {
                data: { vectors, segmentIDs },
            } = response;
            if (vectors) {
                setVectors(vectors || []);
                setSegmentIDs(segmentIDs || []);
            }
        });
    };

    const resetVisualization = newMode => {
        resetSelection();
        setVectors([]);
        httpAPI("", apiPaths.getVectorDBVisualization, {
            data: { id: display.previewVectorDBID, parameters: { algorithm: newMode || mode }, stepContext },
        }).then(response => {
            const {
                data: { vectors, originalVectors, segmentIDs },
            } = response;
            setVectors(vectors || []);
            setOriginalVectors(originalVectors || []);
            setSegmentIDs(segmentIDs || []);
        });
        // httpAPI("", apiPaths.getVectorDBVisualization, {
        //     data: { id: display.previewVectorDBID, parameters: { algorithm: newMode || mode }, stepContext },
        // }).then(response => {
        //     const {
        //         data: { vectors, segmentIDs },
        //     } = response;
        //     setVectors(vectors);
        //     setSegmentIDs(segmentIDs);
        // });
    };

    const visualizeQuery = () => {
        resetSelection();
        setVectors([]);
        setQueryVectors(null);
        const data = {
            id: display.previewVectorDBID,
            testQuery: queryInputRef.current.value,
            parameters: { mode: "full", algorithm: mode },
            stepContext,
        };
        httpAPI("", apiPaths.getTestQueryVisualization, { data }).then(response => {
            const {
                data: { queryVector, originalQueryVector, vectors, originalVectors, segmentIDs },
            } = response;
            if (vectors) {
                // a full run, load up returned vectors??
                setVectors(vectors);
                setOriginalVectors(originalVectors);
                setSegmentIDs(segmentIDs);
                resetSelection();
            }
            console.log("queryVector", queryVector);
            setQueryVectors({ queryVector, originalQueryVector });
        });
    };

    useEffect(() => {
        if (queryVectors) {
            const { queryVector, originalQueryVector } = queryVectors;
            buildDistanceList(queryVector, originalQueryVector);
        }
    }, [queryVectors]);

    const modeChange = e => {
        setMode(e.currentTarget.value);
        if (mode !== e.currentTarget.value) {
            resetVisualization(e.currentTarget.value);
        }
    };

    return open && display.preview && currentStep.index === step.index ? (
        <Draggable handle="#anim-editor-handle" bounds="#root" axis="both" defaultPosition={{ x: 234, y: 50 }}>
            <VectorDBVisualizerPopup>
                <TitleBar id="anim-editor-handle">
                    Vector Database Visualizer
                    <div>
                        <span onClick={closeVisualizer}></span>
                    </div>
                </TitleBar>
                <Panel>
                    <LeftPane>
                        <Controls>
                            <UI.FieldBox title="Vizualization" style={{ width: "fit-content" }}>
                                <VizualizationControls>
                                    <UI.Select
                                        label="Emphasis:"
                                        style={{ width: 130 }}
                                        fit="tight"
                                        selection={mode}
                                        options={modeOptions}
                                        onChange={modeChange}
                                    />
                                    <UI.Select
                                        label="Metric:"
                                        style={{ width: 130 }}
                                        fit="tight"
                                        selection={metric}
                                        options={metricOptions}
                                        onChange={metricTypeChange}
                                    />
                                    <ControlButton disabled={selectedSegmentIndexes.length < 1} onClick={visualizeSelection}>
                                        Visualize selection
                                    </ControlButton>
                                    <ControlButton onClick={() => resetVisualization()}>Reset visualization</ControlButton>
                                </VizualizationControls>
                            </UI.FieldBox>
                            <UI.FieldBox title="Range control">
                                <Slider>
                                    <SliderPlusValue>
                                        <input
                                            type="range"
                                            disabled={selectedVectorIndex === null && queryVectors === null}
                                            value={range}
                                            onChange={rangleSliderChange}
                                        />
                                        <SliderValue>{sliderValue}</SliderValue>
                                    </SliderPlusValue>
                                    <UI.Select
                                        disabled={selectedVectorIndex === null && queryVectors === null}
                                        selection={rangeType}
                                        options={rangeOptions}
                                        onChange={rangeTypeChange}
                                        label="Slider metric:"
                                        style={{ width: 130 }}
                                        fit="tight"
                                    />
                                </Slider>
                            </UI.FieldBox>
                            <UI.FieldBox title="View controls">
                                <ViewCtlButtons>
                                    <ControlButton
                                        disabled={selectedVectorIndex === null && queryVectors === null}
                                        onClick={centerOnSelection}
                                    >
                                        Center on selection
                                    </ControlButton>
                                    <ControlButton onClick={resetView}>Reset view</ControlButton>
                                </ViewCtlButtons>
                            </UI.FieldBox>
                        </Controls>
                    </LeftPane>
                    <RightPane>
                        <UI.FieldBox title="Test query">
                            <TestQueryBox>
                                <QueryInput ref={queryInputRef} minRows={3} autoFocus />
                                <SendQuery disabled={queryInputRef.current?.value === ""} onClick={visualizeQuery}>
                                    <SpinningSender spin={false} />
                                </SendQuery>
                            </TestQueryBox>
                        </UI.FieldBox>
                        <UI.FieldBox title="Neighboring segments" style={{ minWidth: "unset" }}>
                            <NeighborSegments>
                                {selectedSegments.map((seg, i) => {
                                    const dist = segIDToDistanceMap.current[seg._id].distance.toString().substring(0, 6);
                                    const fullSpaceDist = segIDToDistanceMap.current[seg._id].fullSpaceDistance.toString().substring(0, 6);
                                    return (
                                        <Segment key={i}>
                                            <SegExpander onClick={e => clickExpander(e, i)}>{expanded[i] ? "-" : "+"}</SegExpander>
                                            {expanded[i] ? (
                                                <WholeSeg>
                                                    <span>d={dist}</span>
                                                    {seg.segment.text}
                                                </WholeSeg>
                                            ) : (
                                                <ShortSeg>
                                                    <span>
                                                        d={fullSpaceDist} ({dist})
                                                    </span>
                                                    {seg.segment.text}
                                                </ShortSeg>
                                            )}
                                        </Segment>
                                    );
                                })}
                            </NeighborSegments>
                        </UI.FieldBox>
                        <UI.FieldBox title="Word cloud">... cloud ...</UI.FieldBox>
                    </RightPane>
                </Panel>

                <CanvasWrapper>
                    {vectors.length > 0 ? (
                        <ThreeDCanvas ref={canvasRef} />
                    ) : (
                        <Orbits style={{ position: "absolute", top: 235, left: "50%" }} />
                    )}
                </CanvasWrapper>
            </VectorDBVisualizerPopup>
        </Draggable>
    ) : null;
};

const VectorDBVisualizerPopup = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2000;
    width: 900px;
    height: 922px;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    //box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
`;

const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    font-size: 12px;
    background: rgb(221, 220, 220);
    margin: 0px;
    padding: 3px 9px;
    -webkit-box-pack: justify;
    justify-content: space-between;

    span {
        font-family: Webdings;
        color: #868686;
        font-size: 16px;
        padding: 0 2px 3px 2px;
        cursor: pointer;
    }
`;

const Panel = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #f2f0ef;
`;

const Controls = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 12px;
`;

const Slider = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 4px 4px 4px;
    gap: 4px;
    width: fit-content;

    & input {
        width: 170px;
    }
`;

const SliderPlusValue = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`;

const SliderValue = styled.div`
    text-align: right;
    font-family: monospace;
    font-size: 10px;
    width: 30px;
`;

const MetricButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
`;

const RangeTypeButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const RadioButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    cursor: pointer;

    span {
    }
`;

const ViewCtlButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 8px;
    padding: 6px;
`;

const VizualizationControls = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    padding: 8px;
`;

const Divider = styled.div`
    width: 80%;
    border-top: thin solid lightgray;
    margin: 8px 0;
`;

const ControlButton = styled(SimpleButton)`
    padding: 3px 14px;
    font-size: 11px;
    width: 130px;
    font-weight: 300;
`;

const LeftPane = styled.div`
    display: flex;
    flex-direction: column;
    border-right: thin solid lightgray;
    min-width: fit-content;
    padding: 10px;
`;

const RightPane = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding: 10px;
    gap: 10px;
`;

const TestQueryBox = styled.div`
    position: relative;
    width: 100%;
`;

export const QueryInput = styled(TextareaAutosize)`
    border: none;
    font-family:
        DM Sans,
        serif;
    font-size: 12px;
    letter-spacing: 0.4px;
    padding: 5px;
    resize: none;
    width: calc(100% - 11px);

    :focus {
        outline: none;
    }
`;

const SendQuery = styled.div`
    position: absolute;
    right: -7px;
    bottom: -11px;
    cursor: pointer;
    transform: scale(0.9);
`;

const NeighborSegments = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 198px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const NeighborHeader = styled.div`
    font-size: 12px;
    border-bottom: lightgray;
`;

const WorldCloud = styled.div`
    width: 100%;
    min-height: 70px;
`;

const CanvasWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-top: thin solid #ded9d4;
`;

const ThreeDCanvas = styled.canvas`
    height: 100%;
    width: 100%;
`;

const SegmentBox = styled.div`
    height: 40px;
    overflow-y: auto;
`;

const Segment = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const SegExpander = styled.span`
    padding: 0 4px;
    cursor: pointer;
    min-width: fit-content;
    align-self: flex-start;
`;

const WholeSeg = styled.div`
    white-space: pre-wrap;
    font-size: 11px;
    text-align: left;

    span {
        color: #919108;
        margin-right: 4px;
    }
`;

const ShortSeg = styled.div`
    white-space: nowrap;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
        color: #919108;
        margin-right: 4px;
    }
`;

export default VectorDBVisualize;
