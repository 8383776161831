import React, { useMemo, useState, useRef } from "react";
import styled from "styled-components/macro";
import { Card } from "antd";

import ParametersBlock from "@hyperflow/components/hyperflow/displays/ParametersBlock";
import { useFlowGraphStore, useFlowGraphStoreMethods } from "@hyperflow/modules/stores/flowgraph";
import ParametersStack from "@hyperflow/components/hyperflow/displays/ParametersStack";
import ReactDOM from "react-dom";
import VectorDBVisualize from "@hyperflow/components/hyperflow/displays/VectorDBVisualize";
import { LargeClose, nodeClassIcons } from "@hyperflow/components/ui/tools";
import { useViewerStoreMethods } from "@hyperflow/modules/stores/viewer";

const EditParameterPanel = ({ mode, defaultWidth = 350, minWidth = 250 }) => {
    const flow = useFlowGraphStore(state => state.flow);
    const currentStepIndex = useFlowGraphStore(state => state.flow.currentStepIndex);
    const steps = useFlowGraphStore(state => state.flow.steps);
    const currentStep = useMemo(() => steps[currentStepIndex], [flow, steps]);

    const [width, setWidth] = useState(defaultWidth); // Start with minimum width
    const panelRef = useRef(null);
    const isResizing = useRef(false);

    const handleMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
        isResizing.current = true;
        const startX = e.clientX;
        const startWidth = panelRef.current.offsetWidth;

        const handleMouseMove = e => {
            if (isResizing.current) {
                e.preventDefault();
                e.stopPropagation();
                const newWidth = startWidth + (startX - e.clientX);
                if (newWidth >= minWidth) {
                    setWidth(newWidth); // Update panel width
                }
            }
        };

        const handleMouseUp = () => {
            isResizing.current = false;
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
    };

    return (
        <FloatingPanelWrapper className="editor-param-panel param-panel" width={width} ref={panelRef}>
            <StyledCard>
                <StepNodeTitle mode={mode} step={currentStep} />
                <ParametersStack flow={flow} step={currentStep} mode={mode} />
                {currentStep.configurationPreview?.previewVectorDBID &&
                    ReactDOM.createPortal(
                        <VectorDBVisualize step={currentStep} currentStep={currentStep} display={currentStep.configurationPreview} />,
                        document.getElementById("root")
                    )}
            </StyledCard>
            <Resizer onMouseDown={handleMouseDown} />
        </FloatingPanelWrapper>
    );
};

// hey!! refactor this with RunnerParameterPanel...

const StyledCard = styled(Card)`
    width: 100%;
    height: 100%;

    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 12px 12px 0px 0px;
    background: var(--Neutral-Bg-color-bg-default-primary, #fff);
    overflow: hidden;

    .ant-card-body {
        //padding: 8px 6px 8px 16px;
        //overflow-y: scroll;
        display: flex;
        flex-direction: column;
        height: 100%;

        padding: 8px 0px 8px 16px;
        scrollbar-gutter: stable;
        overflow-y: auto;
        margin-right: -4px;
    }
`;

const FloatingPanelWrapper = styled.div`
    position: fixed;
    top: 117px;
    right: 16px;
    bottom: 16px;
    width: ${({ width }) => width}px;
    max-width: ${({ width }) => width}px;
`;

const Resizer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    cursor: ew-resize;
`;

export const StepNodeTitle = ({ mode, step }) => {
    const { toggleStepExpansion } = useFlowGraphStoreMethods();
    const { setFocusedNode } = useViewerStoreMethods();
    const { node } = step;

    return (
        <StepHeader>
            <NodeTitle mode={mode}>
                <div>
                    {nodeClassIcons[node.category]}
                    <span>{node.displayName}</span>
                    {/*<span>{node.nodeName}</span>*/}
                </div>
                <div>
                    {step.index > 0 && <StepNumber>Step {step.index}</StepNumber>}
                    {mode === "historical" && (
                        <StepExpander
                            onClick={() => toggleStepExpansion(step.index)}
                            src={`/assets/images/chevron-${step.expanded ? "up" : "down"}.svg`}
                        />
                    )}
                </div>
                {mode === "configuring" && <LargeClose onClick={() => setFocusedNode(null)} />}
            </NodeTitle>
        </StepHeader>
    );
};
const StepHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 100%;
`;

const StepNumber = styled.div`
    color: var(--Neutral-Text-color-text-tertiary, #99999d);
    font-size: var(--Body-Small-Size, 12px);
    font-weight: 400;
    line-height: var(--Label-Small-Line-Height, 16px); /* 133.333% */
    letter-spacing: var(--Body-Small-Tracking, 0.4px);
    margin-right: 10px;
    text-align: right;
`;

const NodeTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 2px 0 8px 0;

    & div {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    & span {
        color: var(--Neutral-Text-color-text-primary, #1a1a1b);
        font-size: var(--Title-Medium-Size, 16px);
        font-weight: 500;
        line-height: var(--Title-Medium-Line-Height, 24px); /* 150% */
        letter-spacing: var(--Title-Medium-Tracking, 0.15px);
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ${({ mode }) =>
        mode === "historical" &&
        `
        width: 100%;
        justify-content: space-between;
        & span {
            color: var(--Neutral-Text-color-text-primary, #1a1a1b);
            font-size: var(--Title-Small-Size, 14px);
            font-weight: 500;
            line-height: var(--Title-Small-Line-Height, 20px); /* 142.857% */
            letter-spacing: var(--Title-Small-Tracking, 0.1px);
        }
        & div img {
            width: 15px;
        }
    `}
`;

const StepExpander = styled.img`
    cursor: pointer;
`;

export default EditParameterPanel;
