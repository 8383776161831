import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import HyperFlowLayout from "@hyperflow/layouts/HyperFlowLayout";
import { flowgraphStoreUnSubscribeAll, useFlowGraphStoreMethods } from "@hyperflow/modules/stores/flowgraph";
import flowgraphEngine from "@hyperflow/modules/engines/flowgraph";
import TestBotContainer from "@hyperflow/containers/chatbots/TestBotContainer";
import { useChatbotStoreMethods } from "@hyperflow/modules/stores/chatbot";
import { httpAPI } from "@mirinae/apis/http";
import { apiPaths } from "@mirinae/defines/paths";
import { useViewerStoreMethods } from "@hyperflow/modules/stores/viewer";

const TestChatbot = ({}) => {
    const { openFlowGraphInChatbot } = useChatbotStoreMethods();
    const { checkFlowGraph, setFlowGraph } = useFlowGraphStoreMethods();
    const { setEditingEnabled, setFocusedNode } = useViewerStoreMethods();
    const flowGraphStarted = useRef(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const flowgraphID = queryParams.get("fg");
    const sessionID = queryParams.get("s");

    useEffect(() => {
        if (!flowGraphStarted.current && flowgraphID) {
            flowGraphStarted.current = true;
            flowgraphStoreUnSubscribeAll();
            httpAPI("", `${apiPaths.getFlowGraph}/${flowgraphID}`, { method: "GET" })
                .then(response => {
                    const {
                        data: { flowGraph },
                    } = response;
                    flowgraphEngine.initialize().then(() => {
                        checkFlowGraph(flowGraph);
                        setFocusedNode(null);
                        setFlowGraph(flowGraph);
                        setEditingEnabled(false);
                        openFlowGraphInChatbot(flowGraph);
                    });
                })
                .catch(e => {
                    console.log("Error selecting flow-graph");
                });
        }
    }, [queryParams]);

    return (
        <HyperFlowLayout mode="testchatbot">
            <TestBotContainer fullPage />
        </HyperFlowLayout>
    );
};

export default TestChatbot;
