import React from "react";
import { MutatingDots } from "react-loader-spinner";
//   render(<MutatingDots
//   visible={true}
//   height="100"
//   width="100"
//   color="#4fa94d"
//   secondaryColor="#4fa94d"
//   radius="12.5"
//   ariaLabel="mutating-dots-loading"
//   wrapperStyle={{}}
//   wrapperClass=""
//   />)

const LoadingSpinner = ({}) => <MutatingDots />;

export default LoadingSpinner;
