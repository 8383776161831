import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { GoogleLogin } from "@react-oauth/google";
import { useUserStore } from "apps/shared/stores/user";
import { httpAPI } from "@mirinae/apis/http";
import { apiPaths } from "@mirinae/defines/paths";
// import AuthPanel from "@hyperflow/components/auth/AuthPanel";
// import { validateAuthDetails } from "@mirinae/shared/modules/auth/validate";

const GateWrapper = styled.div`
    width: 200px;
    margin: 0 auto;
    padding-top: 20px;
`;

const Error = styled.div`
    background-color: red;
`;

const Gate = () => {
    const { user, methods: userMethods } = useUserStore(state => state);
    const [credential, setCredential] = useState(null);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);

    if (user.id != null) {
        // how did we get here?
        alert("You are already logged in. Try refreshing the page");
        return null;
    }

    useEffect(() => {
        if (credential) {
            httpAPI("", apiPaths.signInGoogle, { data: { credential } })
                .then(({ user }) => {
                    userMethods.setUser(user);
                })
                .catch(_ => alert("Failed to log in to Hyperflow. Please try again"));
        }
    }, [credential]);

    // const onSignUp = async (email, password) => {
    //     setProcessing(true);

    //     const result = validateAuthDetails(email, password);
    //     if (!result.success) {
    //         setError(result.message);
    //     } else {
    //         try {
    //             const user = await httpAPI("", apiPaths.signUp, {
    //                 data: { email, password },
    //             });
    //             userMethods.setUser(user);
    //         } catch (err) {
    //             setError(err.response.data.message);
    //         }
    //     }

    //     setProcessing(false);
    // };

    // const onSignIn = async (email, password) => {
    //     setProcessing(true);

    //     const result = validateAuthDetails(email, password);
    //     if (!result.success) {
    //         setError(result.message);
    //     } else {
    //         try {
    //             const user = await httpAPI("", apiPaths.signIn, {
    //                 data: { email, password },
    //             });
    //             userMethods.setUser(user);
    //         } catch (err) {
    //             setError(err.response.data.message);
    //         }
    //     }

    //     setProcessing(false);
    // };

    return (
        <GateWrapper>
            <GoogleLogin
                onSuccess={credentialResponse => {
                    const { credential } = credentialResponse;
                    setCredential(credential);
                }}
                onError={() => {
                    alert("Google login failed");
                }}
            />
            {/* <AuthPanel onSignIn={onSignIn} onSignUp={onSignUp} /> */}
            {error != null ? <Error>{error.toString()}</Error> : null}
            {processing ? <div>Working...</div> : null}
        </GateWrapper>
    );
};

export default Gate;
