// THIS MAY BE deprecated if we use ant.d themes, or at least limited to global app CSS class defines

import { createGlobalStyle } from "styled-components/macro";

// global-styling shared by all the apps
const HyperFlowGlobalStyling = createGlobalStyle`

    html {
        min-height: 100vh;
    }

    body {
        height: -webkit-fill-available;
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: none;
        -ms-text-size-adjust: 100%;
        min-height: 100vh;
        margin: 0;

        font-family: DM Sans, serif;
        font-size: 13px;
        font-weight: 500;
        color: #1a2c38; // #2d4656;
        background-color: #d7dddb;
        
        #root {
            height: 100vh;
        }

        .panel-background {
            background-color: #eeedea;
        }

        .nav-hover {
            background-color: #e6e7e7;
        }

        .box-border {
            border-color: #c8cbcb;
        }

        .disabled-label {
            color: #b4babd;
        }
        
        // the default bottom drawer shadow is too fierce, this is the only way to control it; jeez
        .ant-drawer-bottom .ant-drawer-content-wrapper {
            //box-shadow:  0 -6px 16px 0 rgba(0, 0, 0, 0.08),
            //0 -3px 6px -4px rgba(0, 0, 0, 0.04),
            //0 -9px 28px 8px rgba(0, 0, 0, 0.05);
            box-shadow: rgba(0, 0, 0, 0.06) 0px -4px 12px 0px, 
            rgba(0, 0, 0, 0.03) 0px -2px 4px -2px, 
            rgba(0, 0, 0, 0.03) 0px -5px 14px 4px;
        }
        
        .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
            padding-top: 2px !important;
            padding-bottom: 2px !important;
            padding-left: 6px !important;
        }
        
    }
`;

export default HyperFlowGlobalStyling;
