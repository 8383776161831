import styled from "styled-components/macro";
import React from "react";

export const ToolBlock = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 16px;
    left: 16px;
    gap: 8px;
    z-index: 1000;
`;

export const MiniToolBar = styled.div`
    background-color: white;
    display: flex;
    height: fit-content;
    padding: 4px;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: var(--Base-Size-XS, 8px);
    background: var(--Neutral-Bg-color-bg-default-primary, #fff);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
`;

export const ToolButton = styled.button`
    all: unset;
    box-sizing: border-box;
    border-radius: 6px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover:not(:disabled) {
        background-color: #eeeef2;
    }

    ${({ $checked }) =>
        $checked &&
        `
        background-color: #eeeef2;
        box-shadow:
            inset 0 2px 2px rgba(0, 0, 0, 0.17), /* reduced blur radius */
            inset 0 -2px 2px rgba(255, 255, 255, 0.5); /* reduced blur radius */
    `}

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain; /* Ensures the SVG keeps its aspect ratio */
        ${({ disabled }) => disabled && "opacity: 0.3;"}
    }
`;

const ButtonImgIcon = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensures the SVG keeps its aspect ratio */
    ${({ disabled }) => disabled && "opacity: 0.3;"}
`;

export const Divider = styled.div`
    width: 1px;
    height: 24px;
    border-left: thin solid #e6e6e9;
    margin: 0px 0px;
    background: #e6e6e9;
`;

export const LargeClose = ({ onClick }) => (
    <CloseToolBtn onClick={onClick}>
        <ButtonImgIcon src="/assets/images/close-large.svg" alt="close" />
    </CloseToolBtn>
);

const CloseToolBtn = styled(ToolButton)`
    position: absolute;
    top: 13px;
    right: 10px;
    width: 18px;
`;

export const NodeIcon = ({ node }) => <ButtonImgIcon src="/assets/images/star-icon.svg" alt="star" />;

export const nodeClassIcons = {
    Annotation: <img src="/assets/images/node-annotation.svg" alt="" />,
    Chat: <img src="/assets/images/node-chat.svg" alt="" />,
    Content: <img src="/assets/images/node-content.svg" alt="" />,
    "Data flow": <img src="/assets/images/node-dataflow.svg" alt="" />,
    "Flow control": <img src="/assets/images/node-flow-control.svg" alt="" />,
    "Knowledge bases": <img src="/assets/images/node-kb.svg" alt="" />,
    LLMs: <img src="/assets/images/node-llms.svg" alt="" />,
    Tools: <img src="/assets/images/node-tools.svg" alt="" />,
};

export default { ToolBlock, MiniToolBar, ToolButton, Divider, LargeClose, nodeClassIcons };
