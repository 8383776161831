import Select from "../parameters/Select";
import ContentUpload from "../parameters/ContentUpload";
import TextEntry from "../parameters/TextEntry";
import URL from "../parameters/URL";
import NumberEntry from "../parameters/NumberEntry";
import DirectorySelect from "../parameters/DirectorySelect";
import BranchChoice from "../parameters/BranchChoice";
import RunButton from "./RunButton";
import CheckBox from "../parameters/CheckBox";
import SelectService from "../parameters/SelectService";
import Prompt from "../parameters/Prompt";
import Instructions from "../parameters/Instructions";
import CSSSelectors from "../parameters/CSSSelectors";
import SliderNumberEntry from "../parameters/SliderNumberEntry";
import PromptTemplate from "./PromptTemplate";
import Match from "./Match";
import Counter from "./Counter";
import MergeInputs from "./MergeInputs";
import PreviewButton from "./PreviewButton";
import LoopControl from "./LoopControl";
import Code from "./Code";

export const ParameterUIComponent = {
    boolean: CheckBox,
    branchChoice: BranchChoice,
    choice: Select,
    code: Code,
    counter: Counter,
    cssSelectors: CSSSelectors,
    dataTypeChoice: Select,
    directorySelect: DirectorySelect,
    fileUpload: ContentUpload,
    instructions: Instructions,
    json: Code,
    loopControl: LoopControl,
    match: Match,
    mergeInputs: MergeInputs, // for now, this stores CounterValue instances to count the number of inputs, needs cleaning up (??)
    number: NumberEntry,
    okButton: RunButton,
    previewButton: PreviewButton,
    prompt: Prompt,
    prompTemplate: PromptTemplate,
    runButton: RunButton,
    service: SelectService,
    slider: SliderNumberEntry,
    template: PromptTemplate, // for backwards copatibility
    text: TextEntry,
    url: URL,
};

export const fullWidthParamterUI = {
    prompt: true,
    instructions: true,
};
