import React, { Suspense, useEffect } from "react";
import LoadingSpinner from "./ui/LoadingSpinner";
import GlobalStyling from "@mirinae/defines/GlobalStyling";

const Base = props => {
    return (
        <>
            <GlobalStyling />
            <Suspense fallback={LoadingSpinner}>{props.children}</Suspense>
        </>
    );
};

export default Base;
