import React, { useState } from "react";
import styled from "styled-components/macro";

import { StepContentBlock } from "@hyperflow/components/ui/widgets";

const CompositePrompt = ({ step, display: { compositePrompt, approximateTokenCount } }) => {
    const Table = ({ style }) => (
        <MessagesTable style={style}>
            <Header style={style}>
                <tr>
                    <th></th>
                    <th>Type</th>
                    <th>Content</th>
                    <th style={{ textAlign: "right" }}>Approx. tokens</th>
                    <th style={{ textAlign: "right" }}>Source step</th>
                </tr>
            </Header>
            <MessageList>
                {compositePrompt?.value.map((cm, i) => {
                    const content =
                        typeof cm.content === "string"
                            ? cm.content
                            : Array.isArray(cm.content)
                              ? cm.content
                                    .map(
                                        cc =>
                                            `{ ${Object.keys(cc)
                                                .map(k => `${k}: ...`)
                                                .join(", ")} }`
                                    )
                                    .join("\n") // crude key dump for now
                              : "-- empty --";
                    return (
                        <Message key={i} style={style}>
                            <Index>{i + 1}.</Index>
                            <Type>{cm.contextType}</Type>
                            <Content>
                                <div>{content.replaceAll("\n\n", "\n")}</div>
                            </Content>
                            <TokenCount>{cm.tokenCount ? cm.tokenCount : "?"}</TokenCount>
                            <SourceStep>{cm.stepIndex}</SourceStep>
                        </Message>
                    );
                })}
            </MessageList>
        </MessagesTable>
    );
    return (
        <StepContentBlock
            title="Composite prompt"
            popupReader={{ defaultWidth: 650 }}
            expander
            bgColor="#faf0fa"
            borderColor="#e6c4a7" // #e59d64"   #e6c4a7
            readerChildren={<Table style={{ fontSize: 12, padding: "6px" }} />}
        >
            <Table style={{ fontSize: 10, padding: "3px 6px" }} />
        </StepContentBlock>
    );
};

const Title = styled.div``;

const Tools = styled.div`
    display: flex;
    justify-content: space-between;
`;

const MessagesTable = styled.table`
    border-collapse: collapse;
    border: thin solid #e0e0e0;
    margin: 6px;
    height: fit-content;
    font-size: ${({ style }) => style.fontSize}px;
`;

const Header = styled.thead`
    th {
        border: thin solid #efefef;
        text-align: left;
        padding: ${({ style }) => style.padding};
        color: var(--Neutral-Text-color-text-primary, #1a1a1b);
        font-weight: 400;
        line-height: var(--Label-Extrasmall-Line-Height, 12px); /* 133.333% */
        letter-spacing: var(--Label-Extrasmall-Tracking, 0.2px);
    }
`;

const MessageList = styled.tbody`
    max-height: 800px;
    overflow-y: scroll;
`;

const Message = styled.tr`
    td {
        padding: ${({ style }) => style.padding};
    }
`;

const Cell = styled.td`
    border: thin solid #efefef;
    vertical-align: top;
    color: var(--Neutral-Text-color-text-secondary, #666669);
    font-weight: 400;
    line-height: var(--Label-Extrasmall-Line-Height, 12px); /* 133.333% */
    letter-spacing: var(--Label-Extrasmall-Tracking, 0.2px);
`;

const Index = styled(Cell)`
    text-align: right;
`;

const Type = styled(Cell)``;

const Content = styled(Cell)`
    div {
        max-height: 36px;
        overflow-y: hidden;
    }
`;

const TokenCount = styled(Cell)`
    text-align: right;
`;

const SourceStep = styled(Cell)`
    text-align: right;
`;

export default CompositePrompt;
