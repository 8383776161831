import React, { useRef, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import classNames from "classnames";

import StepMetadata, { formatters } from "./displays/StepMetadata";
import UI from "@mirinae/hyperflow/components/ui/widgets";

import { SpinningGear } from "@mirinae/hyperflow/components/ui/spinners";
import flowgraphEngine from "@mirinae/hyperflow/modules/engines/flowgraph";
import { useFlowGraphStore } from "@mirinae/hyperflow/modules/stores/flowgraph";
import { useViewerStore } from "@mirinae/hyperflow/modules/stores/viewer";
import RetrievedSegments from "@mirinae/hyperflow/components/hyperflow/displays/RetrievedSegments";

import ParametersBlock from "@hyperflow/components/hyperflow/displays/ParametersBlock";
import SegmentationSet from "@hyperflow/components/hyperflow/displays/SegmentationSet";
import VectorDBVisualize from "@hyperflow/components/hyperflow/displays/VectorDBVisualize";
import CompositePrompt from "@hyperflow/components/hyperflow/displays/CompositePrompt";
import MarkdownView from "react-showdown";
import EditParameterPanel from "@hyperflow/components/flowgraph/editor/EditParameterPanel";
import RunnerParameterPanel from "@hyperflow/components/flowgraph/runner/RunnerParameterPanel";

const HyperFlowWorkspace = ({}) => {
    const flow = useFlowGraphStore(state => state.flow);
    const currentStepIndex = useFlowGraphStore(state => state.flow.currentStepIndex);
    const steps = useFlowGraphStore(state => state.flow.steps);
    const busy = useFlowGraphStore(state => state.flow.busy);
    const focusedNode = useViewerStore(state => state.viewer.focusedNode);
    const workspaceRef = useRef(null);
    const stepCount = useRef(0);

    const stepList = useMemo(() => (flow.runState.startsWith("configuring") ? [steps[0]] : steps.slice(1)), [flow, steps]);
    const currentStep = useMemo(() => steps[currentStepIndex], [flow, steps]);

    useEffect(() => {
        if (workspaceRef.current /* && steps.length > stepCount.current */) {
            // keep scrolling to bottom
            workspaceRef.current.scrollTop = workspaceRef.current.scrollHeight;
            stepCount.current = steps.length;
        }
    }, [steps, busy]);

    return flow.flowGraph ? (
        <PrompterWorkspaceLayout ref={workspaceRef}>
            <StepList>
                {flow.runState.startsWith("configuring")
                    ? focusedNode && (
                          <>
                              <EditParameterPanel mode={"configuring"} />
                              {currentStep.configurationPreview?.previewVectorDBID && (
                                  <VectorDBVisualize
                                      step={currentStep}
                                      currentStep={currentStep}
                                      display={currentStep.configurationPreview}
                                  />
                              )}
                          </>
                      )
                    : stepList.map((step, i) => (
                          <React.Fragment key={i}>
                              {step.index === currentStep.index && flow.runState !== "finished" && (
                                  <RunnerParameterPanel flow={flow} step={step} mode={"current"} />
                              )}
                              {(step.index !== currentStep.index ||
                                  flow.runState === "finished" ||
                                  step.displays?.length > 0 ||
                                  (busy && step.index === steps.length - 1)) && (
                                  <Step data-step={i}>
                                      <PriorStepBlock>
                                          {((step.index !== currentStep.index && step.showParameterHistory) ||
                                              flow.runState === "finished") && (
                                              <ParametersBlock flow={flow} step={step} mode={"historical"} />
                                          )}
                                          {step.displays?.map((d, j) => (
                                              <React.Fragment key={j}>
                                                  {d.promptText && <PriorPrompt>{d.promptText}</PriorPrompt>}
                                                  {d.instructionsText && <PriorInstructions>{d.instructionsText}</PriorInstructions>}
                                                  {d.generatedText && (
                                                      <PriorResponse className={classNames("response", `response-${i}`)}>
                                                          {d.generatedText.includes("**") ? (
                                                              <MarkdownView
                                                                  className="ide-gen-text"
                                                                  markdown={d.generatedText}
                                                                  options={{ tables: true, emoji: true }}
                                                              />
                                                          ) : (
                                                              <div className="gened-text">{d.generatedText}</div>
                                                          )}
                                                      </PriorResponse>
                                                  )}
                                                  {d.generatedImage && (
                                                      <PriorResponse className={classNames("image", `image-${i}`)}>
                                                          {d.generatedImage.dataType === "url" && ( // should always have been made into url form
                                                              // Render URL image
                                                              <img src={d.generatedImage.data} alt="Generated content" />
                                                          )}
                                                      </PriorResponse>
                                                  )}
                                                  {d.generatedVideo && (
                                                      <PriorResponse className={classNames("video", `video-${i}`)}>
                                                          {d.generatedVideo.dataType === "url" && (
                                                              <video controls>
                                                                  <source src={d.generatedVideo.data} type="video/mp4" />
                                                              </video>
                                                          )}
                                                      </PriorResponse>
                                                  )}
                                                  {d.matchOutput && <MatchOutput>{d.matchOutput}</MatchOutput>}
                                                  {d.retrievedSegments && <RetrievedSegments step={step} segments={d.retrievedSegments} />}
                                                  {d.previewVectorDBID && (
                                                      <VectorDBVisualize step={step} currentStep={currentStep} display={d} />
                                                  )}
                                                  {d.segmentationSetID && !busy && (
                                                      <SegmentationSet step={step} currentStep={currentStep} display={d} />
                                                  )}
                                                  {d.compositePrompt && (
                                                      <CompositePrompt step={step} currentStep={currentStep} display={d} />
                                                  )}
                                                  {d.references && (
                                                      <References>
                                                          References:
                                                          {d.references.map((doc, k) => (
                                                              <RefDoc key={k}>
                                                                  {doc.document && <div>Document: {doc.document}</div>}
                                                                  {doc.pages.map((page, m) => (
                                                                      <RefPage key={m}>
                                                                          {page.page && <div>Page {page.page}:</div>}
                                                                          {page.images.map((img, n) => (
                                                                              <img key={n} src={img.data} alt="Reference image" />
                                                                          ))}
                                                                          {page.links.map((lnk, n) => (
                                                                              <a key={n} href={lnk.url} target="_blank">
                                                                                  {lnk.title}
                                                                              </a>
                                                                          ))}
                                                                      </RefPage>
                                                                  ))}
                                                              </RefDoc>
                                                          ))}
                                                      </References>
                                                  )}
                                                  {d.toolCall && (
                                                      <ToolCall>
                                                          Tool: <span>{d.toolName}</span>
                                                          <br />
                                                          Call: <span>{d.toolCall}</span>Tool output: <span>{d.toolOutput}</span>
                                                      </ToolCall>
                                                  )}
                                                  {d.stuff &&
                                                      Object.entries(d.stuff).map(([k, val], i) => {
                                                          const label = `${k[0].toUpperCase()}${Array.from(k.substring(1))
                                                              .map(c => (c === c.toUpperCase() ? ` ${c}` : c))
                                                              .join("")}`;
                                                          const value = (formatters[k] || (i => i))(val);
                                                          return (
                                                              <Stuff key={i}>
                                                                  <span>{label}:</span>
                                                                  {value}
                                                              </Stuff>
                                                          );
                                                      })}
                                                  {d.error && (
                                                      <ErrorResponse>
                                                          {d.service}: {d.message}
                                                      </ErrorResponse>
                                                  )}
                                              </React.Fragment>
                                          ))}
                                          {step.exception && <ExceptionTag>Step failed: {step.exception.exception}</ExceptionTag>}
                                          {step.userMessage && <UserMessage>Message to user: {step.userMessage}</UserMessage>}
                                          {busy && step.index === steps.length - 1 && <BusyStatus />}
                                      </PriorStepBlock>
                                      {step.metadata && <StepMetadata step={step} />}
                                  </Step>
                              )}
                              {flow.runState === "finished" && step.index === currentStep.index && <EndofRun id="end">End of run</EndofRun>}
                              {flow.runState === "failed" && step.index === currentStep.index && (
                                  <Exception>
                                      <Failed>Step failed!</Failed>
                                      {currentStep.exception &&
                                          Object.entries(currentStep.exception).map(([label, info], i) => (
                                              <ExceptionDetail key={i}>
                                                  <span>{label}:</span> {info}
                                              </ExceptionDetail>
                                          ))}
                                  </Exception>
                              )}
                          </React.Fragment>
                      ))}
            </StepList>
        </PrompterWorkspaceLayout>
    ) : null;
};

const PrompterWorkspaceLayout = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    height: 100%;
`;

const StepList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px;
    gap: 6px;
`;

const Step = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const PriorStepBlock = styled.div`
    display: flex;
    flex-direction: column;
    //border: thin solid #ded9d4;
    background-color: #f7f6f5;
    white-space: pre-wrap;
`;

const SummaryBox = styled.div`
    display: flex;
    flex-direction: column;
`;

const StepSummary = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-weight: 500;
    span {
        font-weight: normal;
        text-align: left;
    }
`;

const EndofRun = styled(PriorStepBlock)`
    padding: 3px 6px;
    background-color: #f6e7e4;
`;

const PriorPrompt = styled.div`
    position: relative;
    padding: 6px 6px 6px 30px;
    border-bottom: thin solid #b8c7c1;
    background: #d7dcda;

    ::before {
        display: inline-block;
        position: absolute;
        left: 9px;
        top: 7px;
        content: url("/assets/images/bubble-icon.svg");
        transform: scale(1.5);
    }
`;

const PriorInstructions = styled(PriorPrompt)`
    background: #dcd7d7;
    
    ::before {
        content: url("/assets/images/instructions-prior.svg");
        left: 2px;
        top: 1px;
        transform: scale(0.8);
    }+
`;

const PriorResponse = styled.div`
    position: relative;
    padding: 8px 8px 8px 30px;
    white-space: normal;

    ::before {
        fill: #ecdcdc;
        display: inline-block;
        position: absolute;
        left: 4px;
        top: 6px;
        content: url("/assets/images/brain-1.svg");
        transform: scale(0.85);
        // width: 22px;
    }

    div.ide-gen-text {
        // bloody react-showdown won't specialize with styled-components directly
        p,
        ol,
        li {
            margin-block-start: 0;
        }
    }

    div.gened-text {
        white-space: pre-wrap;
    }

    img {
        max-width: 320px;
    }
`;

const References = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-left: 30px;
    border-top: solid thin lightgray;

    img {
        border-radius: 8px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        max-width: 320px;
    }

    * {
        margin-left: 10px;
    }
`;

const RefDoc = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const RefPage = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const MatchOutput = styled(PriorPrompt)`
    background: #e9ebea;
    
    ::before {
        content: url("/assets/images/instructions-prior.svg");
        left: 2px;
        top: 1px;
        transform: scale(0.8);
    }+
`;

const ErrorResponse = styled(PriorResponse)`
    background-color: #f7dfdf;
    color: #953131;
`;

const BusyStatus = ({}) => {
    const flow = useFlowGraphStore(state => state.flow);
    const currentStepIndex = useFlowGraphStore(state => state.flow.currentStepIndex);
    const steps = useFlowGraphStore(state => state.flow.steps);
    const busy = useFlowGraphStore(state => state.flow.busy);
    const busyMessage = useFlowGraphStore(state => state.flow.busyMessage);
    const busyStatus = useFlowGraphStore(state => state.flow.busyStatus);
    const currentStep = useMemo(() => steps[currentStepIndex], [flow, steps]);
    const busyMsg = useMemo(() => busyMessage || `${currentStep?.nodeDisplayName}`, [flow, steps, busy, busyMessage]);

    return (
        <Busy>
            <SpinningGear />
            {busyMsg}
            {busyStatus ? ": " : ": ..."}
            <span>{busyStatus}</span>
        </Busy>
    );
};

export const Busy = styled.div`
    position: relative;
    height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 30px;

    svg {
        position: absolute;
        left: 3px;
        top: 3px;
    }

    span {
        font-size: 14px;
        color: #1890ff;
        margin-left: 8px;
    }
`;

const Exception = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 6px;
    background-color: #ffd1d1;
    width: 100%;
    gap: 3px;
`;

const Failed = styled.div`
    font-weight: 700;
    color: #cb0000;
`;

const ExceptionDetail = styled.div`
    font-size: 10px;
    white-space: pre;
    margin-left: 5px;
    & > span {
        font-weight: bold;
    }
`;

const ExceptionTag = styled.div`
    background-color: #ffd1d1;
    color: #9a5151;
`;

const UserMessage = styled(Exception)`
    background-color: #f4ede5;
    color: #a35b02;
`;

const ToolCall = styled.div`
    margin-left: 32px;
    line-height: 150%;
    font-size: 13px;

    span {
        font-weight: 600;
    }
`;

const Stuff = styled.div`
    font-weight: 600;
    margin: 6px 8px 3px 30px;
    span {
        font-weight: 400;
        margin: 0 5px;
    }
`;

export default HyperFlowWorkspace;
