import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { Rnd } from "react-rnd";

import TestBot from "@hyperflow/components/chatbots/TestBot";
import { useChatbotStoreMethods } from "@hyperflow/modules/stores/chatbot";

const TestBotContainer = ({ fullPage }) => {
    const { setShowChatbot } = useChatbotStoreMethods();

    const botPopupDefault = useMemo(() => {
        return fullPage
            ? {
                  x: 200,
                  y: 76,
                  width: window.innerWidth - 400,
                  height: window.innerHeight - 250,
              }
            : {
                  x: 25, // window.innerWidth - 480,
                  y: 76,
                  width: 530,
                  height: 620,
              };
    }, [fullPage]);

    const closeChatBot = () => {
        setShowChatbot(false);
    };

    return (
        <Rnd default={botPopupDefault} minWidth={200} minHeight={150} dragHandleClassName="chatbot-handle">
            <TestBotPopup>
                <TitleBar className="chatbot-handle">
                    HyperFlow AI Chat Bot
                    <div>
                        <span onClick={closeChatBot}></span>
                    </div>
                </TitleBar>
                <TestBot />
            </TestBotPopup>
        </Rnd>
    );
};

const TestBotPopup = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 6px;
    height: 100%; // calc(100% - 25px);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    font-size: 14px;
    background: rgb(221, 220, 220);
    margin: 0px;
    padding: 4px 10px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    cursor: grab;

    span {
        font-family: Webdings;
        color: #868686;
        font-size: 16px;
        padding: 0 2px 3px 2px;
        cursor: pointer;
    }
`;

export default TestBotContainer;
