import React from "react";
import { useImmer } from "use-immer";
import styled from "styled-components/macro";

import { StepContentBlock } from "@hyperflow/components/ui/widgets";

const RetrievedSegments = ({ step, segments }) => {
    const [expanded, setExpanded] = useImmer([]);

    const clickExpander = (e, i) => {
        e.stopPropagation();
        setExpanded(state => {
            state[i] = !state[i];
        });
    };
    const Knowledge = ({}) => (
        <SegmentsBox>
            {segments.map((seg, i) => (
                <Segment key={i}>
                    <SegExpander onClick={e => clickExpander(e, i)}>{expanded[i] ? "-" : "+"}</SegExpander>
                    <SegNo>{i.toString().padStart(3, "0")}</SegNo>
                    {expanded[i] ? (
                        <WholeSeg>
                            <span>d={seg.distance}</span>
                            <div>{seg.text}</div>
                            {seg.metadata.length > 0 && (
                                <MetaData>
                                    {seg.metadata.map((md, j) => (
                                        <span>
                                            {md.key}: <strong>{md.value}</strong>
                                        </span>
                                    ))}
                                </MetaData>
                            )}
                        </WholeSeg>
                    ) : (
                        <ShortSeg>{seg.text}</ShortSeg>
                    )}
                </Segment>
            ))}
        </SegmentsBox>
    );

    return (
        <StepContentBlock
            title="Segmentation preview"
            popupReader={{}}
            expander
            bgColor="#faf0fa"
            borderColor="#6485e5"
            readerChildren={<Knowledge />}
        >
            <Knowledge />
        </StepContentBlock>
    );
};

const SegmentsBox = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
`;

const Segment = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const SegExpander = styled.span`
    padding: 0 4px;
    cursor: pointer;
    min-width: fit-content;
    align-self: flex-start;
`;

const WholeSeg = styled.div`
    white-space: pre-wrap;
    font-size: 11px;

    span {
        color: #919108;
        margin-right: 4px;
    }
`;

const ShortSeg = styled.div`
    white-space: nowrap;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MetaData = styled.div`
    margin: 4px 0;
    span {
        color: #1a2c38;
    }
`;

const SegNo = styled.span`
    font-size: 9px;
    font-family: "DM Mono";
    padding: 2px 3px;
    align-self: flex-start;
`;

export default RetrievedSegments;
