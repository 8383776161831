import React, { useMemo, useState, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components/macro";
import { Card } from "antd";

import { useFlowGraphStore } from "@hyperflow/modules/stores/flowgraph";
import ParametersStack from "@hyperflow/components/hyperflow/displays/ParametersStack";
import VectorDBVisualize from "@hyperflow/components/hyperflow/displays/VectorDBVisualize";
import { StepNodeTitle } from "@hyperflow/components/flowgraph/editor/EditParameterPanel";

const ViewerPortal = ({ children }) => {
    const targetElement = document.getElementById("flow-graph-viewer");
    if (!targetElement) return null;

    return ReactDOM.createPortal(children, targetElement);
};

const RunnerParameterPanel = ({ flow, step, mode, defaultWidth = 350, minWidth = 250 }) => {
    const [width, setWidth] = useState(defaultWidth); // Start with minimum width
    const panelRef = useRef(null);
    const isResizing = useRef(false);

    const handleMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
        isResizing.current = true;
        const startX = e.clientX;
        const startWidth = panelRef.current.offsetWidth;

        const handleMouseMove = e => {
            if (isResizing.current) {
                e.preventDefault();
                e.stopPropagation();
                const newWidth = startWidth + (startX - e.clientX);
                if (newWidth >= minWidth) {
                    setWidth(newWidth); // Update panel width
                }
            }
        };

        const handleMouseUp = () => {
            isResizing.current = false;
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
    };

    return step.parameterUI.length > 0 ? (
        <ViewerPortal>
            <FloatingPanelWrapper className="runner-param-panel param-panel" width={width} ref={panelRef}>
                <StyledCard>
                    <StepNodeTitle mode={mode} step={step} />
                    <ParametersStack flow={flow} step={step} mode={mode} />
                </StyledCard>
                <Resizer onMouseDown={handleMouseDown} />
            </FloatingPanelWrapper>
        </ViewerPortal>
    ) : null;
};

const StyledCard = styled(Card)`
    width: 100%;
    height: 100%;

    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 12px 12px 0px 0px;
    background: var(--Neutral-Bg-color-bg-default-primary, #fff);
    overflow: hidden;

    .ant-card-body {
        //padding: 8px 6px 8px 16px;
        //overflow-y: scroll;
        //display: flex;
        //flex-direction: column;
        //height: 100%;

        display: flex;
        flex-direction: column;
        height: 100%;

        padding: 8px 4px 8px 16px;
        scrollbar-gutter: stable;
        overflow-y: auto;
        margin-right: -4px;
    }
`;

const FloatingPanelWrapper = styled.div`
    position: absolute;
    top: 117px;
    right: 0px; // 8px;
    bottom: 16px;
    z-index: 90;
    width: ${({ width }) => width}px;
    max-width: ${({ width }) => width}px;
`;

const Resizer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    cursor: ew-resize;
`;

export default RunnerParameterPanel;
