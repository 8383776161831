import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components/macro";

import flowgraphEngine from "@mirinae/hyperflow/modules/engines/flowgraph";
import { useFlowGraphStore, useFlowGraphStoreMethods } from "@mirinae/hyperflow/modules/stores/flowgraph";
import { fullWidthParamterUI, ParameterUIComponent } from "@mirinae/hyperflow/components/hyperflow/parameters";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { httpAPI } from "@mirinae/apis/http";
import { apiPaths } from "@mirinae/defines/paths";
import { useViewerStore } from "@hyperflow/modules/stores/viewer";
import { useChatbotStore } from "@hyperflow/modules/stores/chatbot";
import { useAppStore } from "@hyperflow/modules/stores/app";
import { useProjectStore } from "@hyperflow/modules/stores/project";

//
// ***********  deprecated ******
//
//    this is the old horizontal-style parameter block

const ParametersBlock = ({ flow, step, mode }) => {
    const appMode = useAppStore(state => state.appMode);
    const { setParamLock, copyStepParamsToNode, isParameterDefined } = useFlowGraphStoreMethods();
    const editingEnabled = useViewerStore(state => state.viewer.editingEnabled);
    const inChatBot = useChatbotStore(state => state.bot.showChatbot);
    const project = useProjectStore(state => state.project);

    const paramUIList = useMemo(() => step.parameterUI.filter(p => !p.dead), [step.parameterUI]);
    const nodeFirstRun = useMemo(() => !flow.steps.find(s => s.nodeID === step.nodeID && step.index !== s.index), [step]);

    const isFullWidth = useMemo(() => paramUIList.some(pui => fullWidthParamterUI[pui.type]), [paramUIList]); // this is a mild hack, clean it up at some point

    const parameterUIColumns = useMemo(() => {
        // split into columns under column break paramUI markers
        let inCol = false,
            startPui;
        const columns = [];
        let curCol = [];
        paramUIList.forEach((pui, i) => {
            pui.index = i;
            if (pui.paramInputIsWired || (pui.configureOnly && mode !== "configuring")) return;
            if (mode === "historical") {
                pui.closed = true;
                pui.locked = undefined;
            } else if (mode === "current" && inChatBot && pui.type === "prompt") {
                return; // handled in chatbot
            } else {
                pui.locked = Boolean(pui.locked);
            }
            if (pui.type === "startColumn") {
                startPui = pui;
                inCol = true;
            } else if (pui.type === "endColumn") {
                if (curCol.length > 0) columns.push([curCol, startPui]);
                curCol = [];
                inCol = false;
            } else if (pui.type === "newColumn") {
                if (curCol.length > 0) columns.push([curCol, startPui]);
                curCol = [];
                inCol = true;
                startPui = pui;
            } else {
                if (inCol) {
                    curCol.push(pui);
                } else {
                    // each element not within a bracket start/end column is in its own column list
                    columns.push([[pui], {}]);
                }
            }
        });
        if (curCol.length > 0) columns.push([curCol, startPui]);
        columns.forEach(([col, spui]) => {
            if (spui) spui.allLocked = col.every(pui => pui.locked);
        });
        return columns;
    }, [paramUIList]);

    const setLocked = async (paramUI, lockState) => {
        const resetting = setParamLock(paramUI.index, lockState);
        if (!lockState || isParameterDefined(paramUI.pathName)) {
            if (resetting) {
                flowgraphEngine.resetParameter(paramUI.index);
            }
            if (editingEnabled && (lockState || resetting)) {
                const updatedFlowGraph = copyStepParamsToNode(step.nodeID);
                await httpAPI("", apiPaths.saveFlowGraph, {
                    data: {
                        flowGraph: updatedFlowGraph,
                        projectID: project._id,
                    },
                });
            }
        }
    };

    return flow.flowGraph && step.node && parameterUIColumns.length > 0 ? (
        <ParamBlock mode={mode} className={`${mode === "historical" ? mode : "active"}-paramblock`}>
            <NodeName>{step.node.displayName?.replace(/\s+/g, "\n")}</NodeName>
            <ScrollingParams>
                {parameterUIColumns.map(
                    ([parameterUIList, startPui], i) =>
                        parameterUIList.length > 0 && (
                            <ParamsColumn key={i} mode={mode} startPui={startPui} isFullWidth={isFullWidth}>
                                {parameterUIList.map((paramUI, j) => {
                                    const ParameterComponent = ParameterUIComponent[paramUI.type];
                                    return ParameterComponent ? (
                                        <ParameterComponent
                                            key={j}
                                            flow={flow}
                                            mode={mode}
                                            step={step}
                                            paramUI={paramUI}
                                            setLocked={lockState => setLocked(paramUI, lockState)}
                                            nodeFirstRun={nodeFirstRun}
                                        />
                                    ) : null;
                                })}
                            </ParamsColumn>
                        )
                )}
            </ScrollingParams>
            {/*<Spacer />*/}
        </ParamBlock>
    ) : null;
};

const ParamsColumn = ({ mode, startPui, isFullWidth, children }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        // mild hack for now to get collapsable parameter blocks
        startPui?.label ? (
            <UI.CollapsingFieldBox
                title={startPui.label}
                expanded={expanded}
                setExpanded={setExpanded}
                locked={startPui.allLocked || mode === "historical"}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    padding: 6,
                }}
            >
                {children}
            </UI.CollapsingFieldBox>
        ) : (
            <SimpleParamsColumn isFullWidth={isFullWidth}>{children}</SimpleParamsColumn>
        )
    );
};

const SimpleParamsColumn = styled.div`
    display: flex;
    flex-direction: column;
    ${props => props.isFullWidth && "flex-grow: 1;"}
    gap: 13px;
`;

const HistoricalParams = css`
    background-color: #f1f4f3;
    border: thin solid #d4ded7;

    ::before {
        content: url("/assets/images/parameters-disp.svg");
        transform: scale(0.7);
    }
`;

export const ParamBlock = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    width: calc(100% - 42px);
    border: thin solid #ded9d4;
    background-color: #f7f6f5; // #f8fbff; // rgb(240, 242, 245); // #f7f6f5;
    // margin: 0px 8px;
    padding: 10px 6px 8px 30px;
    gap: 6px;

    ::before {
        display: inline-block;
        position: absolute;
        left: 4px;
        top: 2px;
        content: url("/assets/images/parameters1.svg");
        transform: scale(0.8);
    }

    ${props => props.mode === "historical" && HistoricalParams};

    #editor & {
        // fitting-in with default ant design, for now
        padding: 13px 30px 16px 30px;
        background-color: #f8fbff; // white;
        border: none;
        width: inherit;

        ::before {
            top: 21px;
        }
        .locked-param {
            background-color: #f4f4f4;
            color: grey;
        }
        .active-paramblock .unlocked-param {
            background-color: white;
        }
        & select {
            border-radius: 6px;
            border: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border);

            :focus {
                outline: var(--ant-line-width) solid var(--ant-color-primary);
            }
            :hover {
                outline: var(--ant-line-width) solid var(--ant-color-primary);
            }
        }

        & option {
            background-color: white;
        }
    }

    #runner & {
        width: 100%;
        .locked-param {
            background-color: #f4f4f4;
            color: grey;
        }
        .active-paramblock .unlocked-param {
            background-color: white;
        }
    }
`;

const ScrollingParams = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    column-gap: 6px;
    flex-wrap: wrap;
    flex-grow: 1;
    row-gap: 20px;
`;

const NodeName = styled.div`
    white-space: pre-wrap;
    text-align: right;
    font-size: 11px;
    position: relative;
    padding-right: 4px;
    min-width: 64px;
    transform: translateY(-3px);
    border-right: thin solid lightgray;
    color: #8d95be;
`;

export default ParametersBlock;
